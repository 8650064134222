<button
  class="button {{ type() }} {{ color() }} {{ size() }} f-semibold-2"
  [ngClass]="{ spinner: showSpinner() }"
  [type]="actionType()"
  [disabled]="disabled()"
>
  @if (showSpinner()) {
    <onyx-spinner color="white"></onyx-spinner>
  } @else {
    <ng-content select="[left]"></ng-content>
    <ng-content></ng-content>
    <ng-content select="[right]"></ng-content>
  }
</button>
