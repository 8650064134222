import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AsYouType, CountryCode } from 'libphonenumber-js';
import { OnyxPhone } from '../interfaces';

@Pipe({
  name: 'onyxPhone',
  standalone: true,
})
export class OnyxPhonePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(phone: OnyxPhone): string {
    const language =
      this.translateService.currentLang ?? this.translateService.defaultLang;
    const formattedNumber = new AsYouType(language as CountryCode).input(
      `${phone.areaCode} ${phone.number}`,
    );

    return formattedNumber;
  }
}
