import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  InjectionToken,
  TemplateRef,
  computed,
  signal,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { isString } from 'lodash';
import { Observable } from 'rxjs';
import { OnyxTooltipContext } from '../../../components';

export interface OnyxTooltipConfig {
  color: OnyxTooltipColor;
  context: OnyxTooltipContext;
  showArrow: boolean;
  arrowPosition$: Observable<{ x: number; y: number }>;
  close: () => void;
}

export const ONYX_TOOLTIP_CONFIG = new InjectionToken<OnyxTooltipConfig>(
  'Tooltip configuration',
);

export type OnyxTooltipColor = 'black' | 'white';

@Component({
  selector: 'onyx-tooltip-overlay',
  standalone: true,
  imports: [AsyncPipe, NgStyle, NgTemplateOutlet, TranslateModule, NgClass],
  templateUrl: './onyx-tooltip-overlay.component.html',
  styleUrls: ['./onyx-tooltip-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxTooltipOverlayComponent {
  protected color = signal<OnyxTooltipColor>('black');
  protected contextAsString = computed(() => {
    const context = this.context();
    return isString(context) ? context : false;
  });
  protected contextAsTemplate = computed(() => {
    const context = this.context();
    return context instanceof TemplateRef ? context : false;
  });

  private context = signal<OnyxTooltipContext | undefined>(undefined);

  constructor(
    @Inject(ONYX_TOOLTIP_CONFIG) public readonly config: OnyxTooltipConfig,
  ) {
    this.context.set(this.config.context);
    this.color.set(this.config.color);
  }

  @HostListener('mouseleave')
  public close(): void {
    this.config.close();
  }
}
