<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
@let functional = allowOpenChange();
<div
  class="container"
  [ngClass]="{ functional }"
  (click)="changeView(CalendarView.CALENDAR)"
>
  <div class="labels">
    <div
      class="label-button"
      [ngClass]="{
        active: view() === CalendarView.MONTHS,
        disabled: view() === CalendarView.YEARS,
      }"
      (click)="changeView(CalendarView.MONTHS); $event.stopPropagation()"
    >
      <p class="f-semibold-2">
        {{ date() | date: 'LLLL' }}
      </p>

      @if (functional) {
        <onyx-icon name="arrow-alt-down" [size]="16"></onyx-icon>
      }
    </div>

    <div
      class="label-button"
      [ngClass]="{
        active: view() === CalendarView.YEARS,
        disabled: view() === CalendarView.MONTHS,
      }"
      (click)="changeView(CalendarView.YEARS); $event.stopPropagation()"
    >
      <p class="f-semibold-2">{{ date() | date: 'y' }}</p>

      @if (functional) {
        <onyx-icon name="arrow-alt-down" [size]="16"></onyx-icon>
      }
    </div>
  </div>

  @if (functional) {
    <div class="buttons">
      <onyx-icon
        name="arrow-alt-left"
        [size]="16"
        (click)="changeMonth.emit(-1)"
      ></onyx-icon>

      <onyx-icon
        name="arrow-alt-right"
        [size]="16"
        (click)="changeMonth.emit(1)"
      ></onyx-icon>
    </div>
  } @else {
    @let from = calendarRole() === CalendarRole.FROM;
    <onyx-icon
      [ngClass]="{ from }"
      [name]="from ? 'arrow-left' : 'arrow-right'"
      [size]="16"
      (click)="changeMonth.emit(from ? -1 : 1)"
    ></onyx-icon>
  }
</div>
