import { HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { OnyxToastService, OnyxToastType } from '@onyx/angular';
import { captureException } from '@sentry/angular-ivy';
import { DateTime } from 'luxon';
import { catchError, map, of } from 'rxjs';
import { PartialDeep } from 'type-fest';
import { ContractorForm } from '../../contractor-form/contractor-form.component';
import { ContractorsRoute } from '../../contractors.routes';
import { ContractorsService } from '../services/contractors.service';

export const editContractorResolver: ResolveFn<
  PartialDeep<ContractorForm> | null
> = (route: ActivatedRouteSnapshot) => {
  const contractorsService = inject(ContractorsService);
  const toastService = inject(OnyxToastService);
  const router = inject(Router);

  const uuid = route.paramMap.get('uuid')!;

  return contractorsService.getContractor(uuid).pipe(
    map(
      (contractor): Partial<ContractorForm> => ({
        companyProfile: {
          ...contractor.companyProfile,
          types: contractor.companyProfile.types.map((type) => type.value),
        },
        contactPersons: contractor.contactPersons,
        documents: contractor.documents.map((document) => ({
          ...document,
          scan: document.scan as File,
        })),
        payments: {
          ...contractor.payments,
          tradeCreditLimit: {
            date:
              contractor.payments.tradeCreditLimit?.date ??
              (DateTime.now().toISODate() as any),
            value: contractor.payments.tradeCreditLimit?.value,
            currency: contractor.payments.tradeCreditLimit?.currency ?? null,
          },
        },
        addresses: contractor.addresses,
      }),
    ),
    catchError((error) => {
      if (error.status === HttpStatusCode.BadRequest) {
        toastService.showError('contractors.toasts.contractorNotFound', {
          keepOnNavigation: true,
        });
      } else {
        toastService.showCustom(OnyxToastType.SERVER_ERROR, {
          keepOnNavigation: true,
        });
        if (error.status !== HttpStatusCode.InternalServerError) {
          captureException(error);
        }
      }

      router.navigateByUrl(ContractorsRoute.CONTRACTORS_LIST);
      return of(null);
    }),
  );
};
