import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnyxStorageService {
  constructor(protected http: HttpClient) {}

  public getBlob(uuid: string): Observable<Blob> {
    return this.http.get(uuid, { responseType: 'blob' });
  }

  public getFile(uuid: string): Observable<File> {
    return this.getBlob(uuid).pipe(
      map((blob) => new File([blob], '', { type: blob.type })),
    );
  }

  public showPreview(target: Blob | File): void {
    window.open(URL.createObjectURL(target));
  }
}
