export const ICON_BUTTONS = {
  primary: {
    blue: 'primary blue',
    green: 'primary green',
    red: 'primary red',
    black: 'primary black',
  },
  outlined: {
    black: 'outlined black',
  },
  ghost: {
    black: 'ghost black',
  },
  transparent: {
    black: 'transparent black',
  },
} as const;
