@if (config.showRelativeDays) {
  <div class="relative-days">
    <button class="relative-day" (click)="setRelativeDate(0)">
      {{ I18N + '.today' | translate }}
    </button>
    <button class="relative-day" (click)="setRelativeDate(1)">
      {{ I18N + '.tomorrow' | translate }}
    </button>
    <button class="relative-day" (click)="setRelativeDate(2)">
      {{ I18N + '.dayAfterTomorrow' | translate }}
    </button>
  </div>
}

<div [ngClass]="{ 'date-range': config.showDateRange }">
  <div class="headbar">
    <onyx-datepicker-calendar-headbar
      [date]="calendars().from[0]"
      [view]="view()"
      [allowOpenChange]="!config.showDateRange"
      [calendarRole]="CalendarRole.FROM"
      (viewChange)="changeView($event)"
      (changeMonth)="changeCalendarMonth($event)"
    ></onyx-datepicker-calendar-headbar>

    @if (config.showDateRange) {
      <onyx-datepicker-calendar-headbar
        [date]="calendars().to[0]"
        [view]="view()"
        [allowOpenChange]="false"
        [calendarRole]="CalendarRole.TO"
        (viewChange)="changeView($event)"
        (changeMonth)="changeCalendarMonth($event)"
      ></onyx-datepicker-calendar-headbar>
    }
  </div>

  <div class="calendars">
    @if (view() === CalendarView.CALENDAR) {
      <onyx-datepicker-calendar
        [calendar]="calendars().from"
        [dateRange]="date()"
        [today]="today()"
        [disableDate]="disableDate()"
        (changeDate)="setDate(date(), $event)"
      ></onyx-datepicker-calendar>
    }

    @if (config.showDateRange) {
      <onyx-datepicker-calendar
        [calendar]="calendars().to"
        [dateRange]="date()"
        [today]="today()"
        [disableDate]="disableDate()"
        (changeDate)="setDateRange(date(), $event)"
      ></onyx-datepicker-calendar>
    }
  </div>
</div>

@if (view() === CalendarView.MONTHS) {
  <div class="view-months">
    @for (_ of [].constructor(12); track $index) {
      @let active = DateTime.fromISO(calendars().from[0]).month === $index + 1;
      <button
        class="list-item"
        [ngClass]="{ active }"
        (click)="selectCalendarMonth($index + 1)"
        #listElement
      >
        <p>{{ I18N + '.months.' + $index | translate }}</p>

        @if (active) {
          <onyx-icon name="check" [size]="12"></onyx-icon>
        }
      </button>
    }
  </div>
} @else if (view() === CalendarView.YEARS) {
  <div class="view-years">
    @for (year of yearsRange(); track year) {
      @let active = DateTime.fromISO(calendars().from[0]).year === year;
      <button
        class="list-item"
        [ngClass]="{ active }"
        (click)="selectCalendarYear(year)"
        #listElement
      >
        <p>{{ year }}</p>

        @if (active) {
          <onyx-icon name="check" [size]="12"></onyx-icon>
        }
      </button>
    }
  </div>
}
