import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'onyx-message-action-button',
  standalone: true,
  imports: [NgClass],
  templateUrl: './onyx-message-action-button.component.html',
  styleUrl: './onyx-message-action-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxMessageActionButtonComponent {
  public bold = input(false);
}
