import { FormGroup } from '@angular/forms';
import { onyxNipNumberValidator } from './onyx-nip-number.validator';

export const onyxVatIdValidator = (
  countryControlName = 'country',
  vatIdControlName = 'vatId',
) => {
  return (form: FormGroup): void => {
    const countryControl = form.get(countryControlName)!;
    const vatIdControl = form.get(vatIdControlName)!;
    if (!countryControl.value || !vatIdControl.value) return;

    if (countryControl.value === 'pl') {
      const nipNumberError = onyxNipNumberValidator(vatIdControl);
      vatIdControl.setErrors(nipNumberError ?? null);
    } else {
      vatIdControl.setErrors(null);
    }
  };
};
