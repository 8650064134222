import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  input,
} from '@angular/core';

@Component({
  selector: 'onyx-link',
  standalone: true,
  imports: [NgClass],
  templateUrl: './onyx-link.component.html',
  styleUrl: './onyx-link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxLinkComponent implements AfterViewInit {
  public color = input.required<'gray' | 'black' | 'blue' | 'white'>();
  public font = input<`f-${string}`>('f-link-1');
  public disabled = input(false);
  public href = input<string>();
  public target = input<'_blank' | '_self' | '_parent' | '_top'>('_blank');

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  public ngAfterViewInit(): void {
    this.elementRef.nativeElement.tabIndex = -1;
  }
}
