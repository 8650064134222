import { Overlay } from '@angular/cdk/overlay';
import { DestroyRef, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { distinctUntilChanged, map } from 'rxjs';
import {
  DEFAULT_TOAST_COLORS,
  DEFAULT_TOAST_ICONS,
  TOAST_ERROR_ICON,
  TOAST_INFORMATION_ICON,
  TOAST_SUCCESS_ICON,
} from '../components/toast/constants';
import { OnyxToastColor, OnyxToastType } from '../components/toast/enums';
import {
  OnyxToast,
  OnyxToastMessage,
  OnyxToastOverride,
} from '../components/toast/interfaces';
import {
  ONYX_TOASTER_CONFIG,
  OnyxToasterComponent,
} from '../components/toast/onyx-toaster/onyx-toaster.component';
import { I18N_NAMESPACE } from '../internal/constants';
import { OnyxBaseOverlayService } from '../internal/services/onyx-base-overlay.service';

@Injectable({
  providedIn: 'root',
})
export class OnyxToastService {
  private readonly overlayService: OnyxBaseOverlayService;

  private toasts = signal<OnyxToast[]>([]);

  private routeChange$ = this.router.events.pipe(
    map(() => this.router.url.split('?')[0]),
    distinctUntilChanged(),
    map(() => undefined),
    takeUntilDestroyed(this.destroyRef),
  );

  constructor(
    private router: Router,
    private destroyRef: DestroyRef,
    private overlay: Overlay,
  ) {
    this.overlayService = new OnyxBaseOverlayService(this.overlay);
    this.attachToasterOverlay();

    this.routeChange$.subscribe(() =>
      this.toasts.update((toasts) => toasts.filter((t) => t.keepOnNavigation)),
    );
  }

  public showSuccess(
    message: OnyxToastMessage,
    override?: OnyxToastOverride,
  ): OnyxToast {
    return this.showToast({
      color: OnyxToastColor.GREEN,
      icon: override?.icon ?? TOAST_SUCCESS_ICON,
      message,
      keepOnNavigation: override?.keepOnNavigation,
    });
  }

  public showError(
    message: OnyxToastMessage,
    override?: OnyxToastOverride,
  ): OnyxToast {
    return this.showToast({
      color: OnyxToastColor.RED,
      icon: override?.icon ?? TOAST_ERROR_ICON,
      message,
      keepOnNavigation: override?.keepOnNavigation,
    });
  }

  public showInformation(
    message: OnyxToastMessage,
    override?: OnyxToastOverride,
  ): OnyxToast {
    return this.showToast({
      color: OnyxToastColor.GRAY,
      icon: override?.icon ?? TOAST_INFORMATION_ICON,
      message,
      keepOnNavigation: override?.keepOnNavigation,
    });
  }

  public showCustom(
    type: OnyxToastType,
    override?: OnyxToastOverride,
  ): OnyxToast {
    const toast = this.mapType(type, override);
    return this.showToast(toast);
  }

  public hideToast(toast: OnyxToast): void {
    this.toasts.update((toasts) => toasts.filter((t) => t !== toast));
  }

  private showToast(toast: OnyxToast): OnyxToast {
    this.toasts.update((toasts) => [toast, ...toasts]);
    return toast;
  }

  private mapType(
    type: OnyxToastType,
    override?: OnyxToastOverride,
  ): OnyxToast {
    const color = DEFAULT_TOAST_COLORS[type];
    const message = `${I18N_NAMESPACE}.toasts.${type}`;
    const icon = DEFAULT_TOAST_ICONS[type];

    return {
      color,
      icon: override?.icon ?? icon,
      message,
      keepOnNavigation: override?.keepOnNavigation,
    };
  }

  private attachToasterOverlay(): void {
    const overlayRef = this.overlayService.createOverlayRef({
      positionStrategy: this.overlay.position().global(),
    });
    const componentInjector = this.overlayService.createComponentInjector(
      ONYX_TOASTER_CONFIG,
      {
        toasts: this.toasts.asReadonly(),
        hideToast: (toast) => this.hideToast(toast),
      },
    );
    const componentPortal = this.overlayService.createComponentPortal(
      OnyxToasterComponent,
      componentInjector,
    );

    this.overlayService.setOverlayRef(overlayRef);
    this.overlayService.attachToOverlay(componentPortal);
  }
}
