@if (scans().length !== 0) {
  <div class="buttons">
    <onyx-icon-button
      type="outlined"
      color="black"
      size="m"
      onyxTooltip="buttons.openInNewTab"
      [onyxTooltipDelay]="TOOLTIP_DELAY"
      (click)="showFiles()"
    >
      <onyx-icon name="new-tab" [size]="16"></onyx-icon>
    </onyx-icon-button>

    <onyx-icon-button
      type="outlined"
      color="black"
      size="m"
      onyxTooltip="buttons.download"
      [onyxTooltipDelay]="TOOLTIP_DELAY"
      (click)="downloadFiles()"
    >
      <onyx-icon name="download" [size]="16"></onyx-icon>
    </onyx-icon-button>
  </div>
}
