import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  computed,
  input,
} from '@angular/core';
import { ButtonActionType } from '../../../internal/interfaces';
import { OnyxSpinnerComponent } from '../../spinner';

@Component({
  selector: 'onyx-button',
  standalone: true,
  imports: [OnyxSpinnerComponent, NgClass],
  templateUrl: './onyx-button.component.html',
  styleUrl: './onyx-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxButtonComponent {
  public type = input<'primary' | 'outlined' | 'ghost' | 'transparent'>(
    'primary',
  );
  public color = input<'blue' | 'green' | 'red' | 'black'>('blue');
  public size = input<'s' | 'm'>('m');
  public spinner = input(false);
  public actionType = input<ButtonActionType>('button');
  public disabled = input(false);

  protected showSpinner: Signal<boolean> = computed(
    () => this.spinner() && this.color() === 'blue',
  );
}
