import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  ONYX_TOOLTIP_DELAY,
  OnyxButtonComponent,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxOverlayPosition,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-modal-documents-buttons',
  standalone: true,
  imports: [
    OnyxButtonComponent,
    OnyxIconComponent,
    OnyxIconButtonComponent,
    TranslateModule,
    OnyxTooltipDirective,
  ],
  templateUrl: './modal-documents-buttons.component.html',
  styleUrl: './modal-documents-buttons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverModalDocumentsButtonsComponent {
  protected readonly OnyxOverlayPosition = OnyxOverlayPosition;
  protected readonly TOOLTIP_DELAY = ONYX_TOOLTIP_DELAY;

  public scans = input.required<string[]>();

  constructor(private storageService: StorageService) {}

  protected showFiles(): void {
    this.scans().forEach((file) => {
      this.storageService.openFile(file).subscribe();
    });
  }

  protected downloadFiles(): void {
    this.scans().forEach((file) => {
      this.storageService.downloadFile(file).subscribe();
    });
  }
}
