import { OnyxAddress } from '../components';

export class AddressHelper {
  public static composeLabel(
    address: Omit<OnyxAddress, 'type' | 'label' | 'coordinates'>,
  ): string {
    return `${address.street} ${address.houseNumber}${address.apartmentNumber ? `/${address.apartmentNumber}` : ''}, ${address.zipCode} ${address.city}, ${address.countryCode?.toUpperCase()}`;
  }

  public static composeCoordinatesLabel(
    address: Pick<OnyxAddress, 'coordinates'>,
  ): string {
    const coordinates = address.coordinates;
    return `${+coordinates.latitude.toFixed(5)}, ${+coordinates.longitude.toFixed(5)}`;
  }
}
