<div class="tooltip {{ color() }}">
  @if (config.showArrow) {
    @let arrowPosition = config.arrowPosition$ | async;
    <span
      class="arrow {{ color() }}"
      [ngStyle]="{
        'left.px': arrowPosition?.x,
        'bottom.px': arrowPosition?.y,
      }"
    ></span>
  }

  @if (contextAsString(); as string) {
    <span [ngClass]="{ 'f-medium-1': color() === 'white' }">
      {{ string | translate }}
    </span>
  }
  @if (contextAsTemplate(); as template) {
    <ng-template [ngTemplateOutlet]="template"></ng-template>
  }
</div>
