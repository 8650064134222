@if (label(); as label) {
  <onyx-input-label
    [disabled]="disabled()"
    [optional]="isOptional()"
    [label]="label"
    [hint]="hint()"
    (click)="focus()"
  ></onyx-input-label>
}

<div
  class="tabs {{ size() }}"
  [ngClass]="{
    fill: fill(),
    invalid: isInvalid(),
    disabled: disabled(),
  }"
  #containerElement
>
  @for (tab of tabs(); track tab.value) {
    @let icon = $any(tab).icon;
    <button
      class="tab f-regular-1"
      [ngClass]="{
        active: $index === activeTabIndex(),
        disabled: tab.disabled ?? disabled(),
        icon,
      }"
      type="button"
      [tabindex]="tab.disabled ? -1 : 0"
      [onyxTooltip]="tab.tooltip"
      [onyxTooltipDelay]="TOOLTIP_DELAY"
      (click)="changeTab($index)"
      (keydown.space)="changeTab($index)"
      #tabElement
    >
      @if (icon) {
        <onyx-icon
          [name]="icon.name"
          [size]="icon.size"
          [frame]="icon.frame"
        ></onyx-icon>
      } @else {
        @let tabName = $any(tab).name | translate;
        <p [attr.content]="tabName">{{ tabName }}</p>
      }
    </button>
  }

  <div class="indicator" #indicatorElement></div>
</div>

@if (formControl() && showErrors()) {
  <onyx-form-control-error
    [formControls]="[formControl()!]"
  ></onyx-form-control-error>
}
