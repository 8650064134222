import { DateTime } from 'luxon';
import { DOCUMENT_EXPIRATION_WARNING_THRESHOLD } from '../constants/document-expiration-warning-threshold';

export type CellColor = 'transparent' | 'red' | 'yellow';
type RowColor = 'gray' | 'red' | 'yellow';

export class ColorHelper {
  public static getCellColor(
    date: string | null | undefined,
    showWarnings = true,
  ): CellColor {
    if (!date || !showWarnings) return 'transparent';

    const expiryDate = DateTime.fromISO(date);
    const days = expiryDate.diffNow('days').days;

    if (days <= 0) {
      return 'red';
    } else if (days <= DOCUMENT_EXPIRATION_WARNING_THRESHOLD) {
      return 'yellow';
    }
    return 'transparent';
  }

  public static getInformationRowColor(
    date: string | null | undefined,
    showWarnings = true,
  ): RowColor {
    const color = this.getCellColor(date, showWarnings);
    return color === 'transparent' ? 'gray' : color;
  }

  public static findCellColor(colors: CellColor[] | undefined): CellColor {
    if (!colors) return 'transparent';

    return (
      colors.find((color) => color === 'red') ||
      colors.find((color) => color === 'yellow') ||
      'transparent'
    );
  }
}
