import { OnyxToastType } from '../components/toast';
import { OnyxPhone } from '../interfaces';
import { OnyxToastService } from '../services';

export class ActionHelper {
  public static copy(text: string, toastService: OnyxToastService): void {
    navigator.clipboard.writeText(text);
    toastService.showCustom(OnyxToastType.COPIED_TO_CLIPBOARD);
  }

  public static makeCall(phone: OnyxPhone): void {
    window.open(`tel:${phone.areaCode}${phone.number}`, '_self');
  }

  public static openLink(link: string): void {
    window.open(link, '_blank');
  }

  public static sendEmail(email: string): void {
    window.open(`mailto:${email}`, '_self');
  }

  public static downloadFile(file: string, url: string): void {
    const helperLink = document.createElement('a');
    helperLink.href = url;
    helperLink.download = file;
    document.body.appendChild(helperLink);
    helperLink.click();
    document.body.removeChild(helperLink);
    URL.revokeObjectURL(url);
  }
}
