import { Injectable, Signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class OnyxPreferencesService {
  public abstract defaultAreaCode: Signal<string>;
  public abstract defaultCurrency: Signal<string>;
  public abstract defaultCountry: Signal<string>;
}
