import {
  AbstractControl,
  AbstractControlOptions,
  FormArray,
  FormGroup,
  NonNullableFormBuilder,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { OnyxAmountForm, OnyxNumberRange } from '../interfaces';
import { OnyxVatId } from '../interfaces/onyx-vat-id';
import { OnyxPreferencesService } from '../services';
import {
  onyxDateValidator,
  onyxNumberRangeValidator,
  onyxVatIdValidator,
} from '../validators';

export class FormHelper {
  public static submit(control: AbstractControl): void {
    if (control instanceof FormGroup || control instanceof FormArray) {
      for (const child of Object.values(control.controls)) this.submit(child);
    }

    control.markAsTouched({ onlySelf: true });
    control.markAsDirty({ onlySelf: true });
    control.setErrors(null, { emitEvent: false });
    control.updateValueAndValidity({ onlySelf: true });
  }

  public static reset(control: AbstractControl): void {
    control.reset();
    this.resetChild(control);
  }

  public static buildAmountForm(
    fb: NonNullableFormBuilder,
    preferencesService: OnyxPreferencesService,
    options?: Partial<{
      minValue: number;
      value: OnyxAmountForm;
      disabled: boolean;
    }>,
  ) {
    return fb.group({
      date: this.createControl(
        fb,
        options?.value?.date ?? null,
        options?.disabled,
        [
          ...(options?.value?.date ? [Validators.required] : []),
          onyxDateValidator,
        ],
      ),
      value: this.createControl(fb, options?.value?.value, options?.disabled, [
        Validators.required,
        ...(options?.minValue != null
          ? [Validators.min(options?.minValue)]
          : []),
      ]),
      currency: this.createControl(
        fb,
        options?.value?.currency ?? preferencesService.defaultCurrency(),
        options?.disabled,
        [Validators.required],
      ),
    });
  }

  public static buildNumberRangeForm(
    fb: NonNullableFormBuilder,
    options?: Partial<{
      decimalPlaces: number;
      minValue: number;
      maxValue: number;
      value: OnyxNumberRange;
      disabled: boolean;
    }>,
  ) {
    return fb.group(
      {
        from: this.createControl(fb, options?.value?.from, options?.disabled, [
          Validators.required,
          ...(options?.minValue != null
            ? [Validators.min(options?.minValue)]
            : []),
        ]),
        to: this.createControl(fb, options?.value?.to, options?.disabled, [
          Validators.required,
          ...(options?.maxValue != null
            ? [Validators.max(options?.maxValue)]
            : []),
        ]),
      },
      {
        validators: [onyxNumberRangeValidator(options?.decimalPlaces)],
      } as AbstractControlOptions,
    );
  }

  public static buildVatIdForm(
    fb: NonNullableFormBuilder,
    preferencesService: OnyxPreferencesService,
    options?: Partial<{
      value: OnyxVatId;
      disabled: boolean;
    }>,
  ) {
    return fb.group(
      {
        country: this.createControl(
          fb,
          options?.value?.country ?? preferencesService.defaultCountry(),
          options?.disabled,
          [Validators.required],
        ),
        vatId: this.createControl(
          fb,
          options?.value?.vatId,
          options?.disabled,
          [Validators.required],
        ),
      },
      {
        validators: [onyxVatIdValidator()],
      } as AbstractControlOptions,
    );
  }

  private static resetChild(control: AbstractControl): void {
    control.markAsPristine({ onlySelf: true });
    control.markAsUntouched({ onlySelf: true });
    control.setErrors(null, { emitEvent: false });
    control.updateValueAndValidity({ onlySelf: true });

    if (control instanceof FormGroup) {
      const children = Object.values(control.controls);
      for (const child of children) this.resetChild(child);
    } else if (control instanceof FormArray) {
      control.clear();
      control.reset();
    }
  }

  private static createControl<T>(
    fb: NonNullableFormBuilder,
    value: T,
    disabled = false,
    validators: ValidatorFn[],
  ) {
    return fb.control<T | null>(
      {
        value: value ?? null,
        disabled,
      },
      validators,
    );
  }
}
