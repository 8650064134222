import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnyxPaginated, OnyxPagination } from '@onyx/angular';
import { isString } from 'lodash';
import { map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { PickDeep } from 'type-fest';
import { BatchFileUpload } from '../../../../common/interfaces/utilities/batch-file-upload';
import { ApiService } from '../../../../common/services/api.service';
import { StorageService } from '../../../../common/services/storage.service';
import { FleetFormDto } from '../../fleet-form/fleet-form.component';
import { FleetAssignDriverModalForm } from '../components/fleet-assign-driver-modal/fleet-assign-driver-modal.component';
import { FleetCategory } from '../enums/fleet-category';
import { FleetState } from '../enums/fleet-state';
import { Fleet } from '../interfaces/fleet';

@Injectable({
  providedIn: 'root',
})
export class FleetService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(
    protected override http: HttpClient,
    private storageService: StorageService,
  ) {
    super(http);
  }

  public listFleet(
    params: {
      state: FleetState;
      category: FleetCategory[] | null;
      showSetsOnly: boolean;
      showAssignedOnly: boolean;
    } & OnyxPagination,
  ): Observable<OnyxPaginated<Fleet>> {
    return this.get('/fleet', {
      params: {
        state: params.state,
        'category[]': params.category ?? [],
        showSetsOnly: params.showSetsOnly,
        showAssignedOnly: params.showAssignedOnly,
        page: params.page,
        limit: params.limit,
      },
    });
  }

  public getFleet(category: FleetCategory, uuid: string): Observable<Fleet> {
    const path = this.getPath(category);
    return this.get(`/${path}/${uuid}`);
  }

  public addFleet(dto: FleetFormDto): Observable<Fleet> {
    return this.uploadFiles(dto).pipe(
      switchMap((dto) => this.post<Fleet>(`/${this.getPath(dto)}`, dto)),
    );
  }

  public editFleet(uuid: string, dto: FleetFormDto): Observable<Fleet> {
    return this.uploadFiles(dto).pipe(
      switchMap((dto) => this.put<Fleet>(`/${this.getPath(dto)}/${uuid}`, dto)),
    );
  }

  public deleteFleet(dto: Fleet): Observable<void> {
    return this.delete<void>(`/${this.getPath(dto)}/${dto.uuid}`).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public assignDrivers(
    form: FleetAssignDriverModalForm,
    uuid: string,
  ): Observable<void> {
    return this.put<void>(`/vehicles/${uuid}/assign-drivers`, form).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public unassignDrivers(driverUuid: string, uuid: string): Observable<void> {
    return this.post<void>(`/vehicles/${uuid}/unassign-drivers`, {
      driverUuid,
    }).pipe(tap(() => this._reload$.next()));
  }

  public assignEmployees(
    employees: { employees: string[] },
    uuid: string,
  ): Observable<void> {
    return this.put<void>(
      `/vehicles/${uuid}/assigned-employees`,
      employees,
    ).pipe(tap(() => this._reload$.next()));
  }

  public batchFleet(
    vehiclesUuid: string[],
    setFields: {
      isForSale?: boolean;
      state?: FleetState;
      assignedEmployees?: string[];
    },
  ): Observable<void> {
    return this.put<void>('/fleet/batch', { vehiclesUuid, setFields }).pipe(
      tap(() => this._reload$.next()),
    );
  }

  private getPath(
    dto: FleetCategory | PickDeep<Fleet, 'generalInformation.category'>,
  ): string {
    const category = isString(dto) ? dto : dto.generalInformation.category;
    return category.includes('trailer') ? 'trailers' : 'vehicles';
  }

  private uploadFiles(dto: FleetFormDto): Observable<FleetFormDto> {
    return of(dto).pipe(
      map((dto): BatchFileUpload[] => [
        {
          path: 'registrationCertificate.scan',
          files: dto.registrationCertificate.scan,
        },
        ...(dto.co2Emissions
          ? [
              {
                path: 'co2Emissions.emissionCertificate',
                files: dto.co2Emissions.emissionCertificate,
              },
            ]
          : []),
        {
          path: 'thirdPartyLiabilityInsurance.contractScan',
          files: dto.thirdPartyLiabilityInsurance.contractScan,
        },
        ...(dto.comprehensiveInsurance
          ? [
              {
                path: 'comprehensiveInsurance.contractScan',
                files: dto.comprehensiveInsurance.contractScan,
              },
            ]
          : []),
      ]),
      switchMap((data) => this.storageService.uploadBatch(data)),
      map((data) => this.storageService.mergeBatch(dto, data)),
    );
  }
}
