export enum CalendarView {
  CALENDAR,
  MONTHS,
  YEARS,
}

export enum CalendarRole {
  FROM,
  TO,
}
