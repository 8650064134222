<button
  class="tag {{ color() }}"
  [ngClass]="{ disabled: disabled(), 'show-close': showClose() }"
>
  <div class="content">
    <ng-content></ng-content>

    @if (count()) {
      <span class="count">{{ count() }}</span>
    }
  </div>

  @if (showClose() && !disabled()) {
    <onyx-icon class="button" name="close" [size]="10"></onyx-icon>
  }
</button>
