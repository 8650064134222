<p class="label f-medium-3">{{ label() }}</p>

<div class="content f-medium-3">
  @if (value() != null) {
    <p class="value">
      {{ value() | number: '1.0-2' }}

      @if (unit()) {
        <span class="unit">{{ unit() }}</span>
      }
    </p>
  }

  <ng-content></ng-content>
</div>
