import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'onyx-pagination-item',
  standalone: true,
  imports: [DecimalPipe],
  templateUrl: './onyx-pagination-item.component.html',
  styleUrl: './onyx-pagination-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxPaginationItemComponent {
  public label = input.required<string>();
  public value = input<number>();
  public unit = input<string>();
}
