<onyx-modal
  type="center"
  [heading]="I18N + '.' + data.mode + 'Heading' | translate"
  size="m"
  [showClose]="!loading()"
  [close$]="close$"
  (closeModal)="dialogRef.close($event ?? undefined)"
>
  <form [formGroup]="form">
    <onyx-text-field
      [formControl]="form.controls.name"
      [label]="I18N + '.name' | translate"
    ></onyx-text-field>

    <div class="columns">
      <onyx-search color="white" [(query)]="query"></onyx-search>

      <div class="list">
        <p class="label f-regular-3">
          {{ I18N + '.visibleColumns' | translate }}
        </p>

        <ul
          cdkDropList
          [cdkDropListSortPredicate]="dropPredicate.bind(this)"
          (cdkDropListDropped)="drop($event)"
        >
          @for (
            column of visibleColumns() | onyxFilter: query() : ['name'];
            track column.id
          ) {
            <li
              class="item"
              [ngClass]="{ disabled: column.required }"
              cdkDrag
              [cdkDragDisabled]="column.required"
            >
              @if (!column.required) {
                <onyx-icon-button
                  type="transparent"
                  color="black"
                  size="xs"
                  (click)="hideColumn(column.id)"
                >
                  <onyx-icon name="delete" [size]="16"></onyx-icon>
                </onyx-icon-button>
              } @else {
                <div></div>
              }

              <ng-container
                *ngTemplateOutlet="columnNameTemplate; context: { column }"
              ></ng-container>

              @if (!column.required) {
                <onyx-icon
                  class="drag"
                  name="drag-vertical"
                  [size]="16"
                  cdkDragHandle
                ></onyx-icon>
              }
            </li>
          } @empty {
            <li class="item empty f-italic">
              {{ I18N + '.noneMatching' | translate }}
            </li>
          }
        </ul>
      </div>

      <div class="list">
        <p class="label f-regular-3">
          {{ I18N + '.hiddenColumns' | translate }}
        </p>

        <ul>
          @for (
            column of hiddenColumns() | onyxFilter: query() : ['name'];
            track column.id
          ) {
            <li
              class="item active"
              tabindex="-1"
              (keydown.space)="showColumn(column)"
              (click)="showColumn(column)"
            >
              <onyx-icon-button type="transparent" color="black" size="xs">
                <onyx-icon name="zoom-in" [size]="16"></onyx-icon>
              </onyx-icon-button>

              <ng-container
                *ngTemplateOutlet="columnNameTemplate; context: { column }"
              ></ng-container>
            </li>
          } @empty {
            <li class="item empty f-italic">
              {{
                I18N +
                  (hiddenColumns().length ? '.noneMatching' : '.noneHidden')
                  | translate
              }}
            </li>
          }
        </ul>
      </div>
    </div>
  </form>

  <ng-container bottomLeftOptions>
    @if (data.mode === OnyxFormMode.EDIT) {
      <onyx-button
        type="outlined"
        color="red"
        [disabled]="!!loading()"
        [spinner]="loading() === 'delete'"
        (click)="loading() ? null : delete()"
      >
        {{ I18N + '.delete' | translate }}
      </onyx-button>
    }

    <onyx-button
      type="outlined"
      color="black"
      [disabled]="!!loading()"
      (click)="loading() ? null : restoreDefaults()"
    >
      {{ I18N + '.restoreDefaults' | translate }}
    </onyx-button>
  </ng-container>

  <ng-container bottomRightOptions>
    <onyx-button
      type="outlined"
      color="black"
      [disabled]="!!loading()"
      (click)="loading() ? null : cancel()"
    >
      {{ 'onyxAngular.common.cancel' | translate }}
    </onyx-button>

    <onyx-button
      [disabled]="!!loading()"
      [spinner]="loading() === 'submit'"
      (click)="loading() ? null : submit()"
    >
      {{ I18N + '.' + data.mode | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>

<ng-template let-column="column" #columnNameTemplate>
  <p
    [innerHTML]="
      column.name ?? column.tooltip ?? '' | translate | onyxHighlight: query()
    "
  ></p>
</ng-template>
