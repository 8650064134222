<div class="container">
  <input
    class="{{ size() }} {{ color() }}"
    [ngClass]="{
      focus: showFocus(),
      active: query(),
    }"
    type="text"
    [placeholder]="I18N | translate"
    (input)="changeValue($event)"
    #searchElement
  />

  <div class="actions">
    <div class="hidden">
      @if (query()) {
        <onyx-clear-button (click)="clearValue()"></onyx-clear-button>
      }
    </div>
  </div>

  <onyx-icon name="search" [size]="16"></onyx-icon>
</div>
