import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  InjectionToken,
} from '@angular/core';
import { OnyxToasterConfig } from '../interfaces';
import { OnyxToastComponent } from './onyx-toast/onyx-toast.component';

export const ONYX_TOASTER_CONFIG = new InjectionToken<OnyxToasterConfig>(
  'Toaster configuration',
);

@Component({
  selector: 'onyx-toaster',
  standalone: true,
  imports: [OnyxToastComponent],
  templateUrl: './onyx-toaster.component.html',
  styleUrl: './onyx-toaster.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxToasterComponent {
  constructor(
    @Inject(ONYX_TOASTER_CONFIG) protected config: OnyxToasterConfig,
  ) {}
}
