import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { OnyxIconComponent } from '@onyx/angular';

@Component({
  selector: 'app-unavailability-file-cell',
  standalone: true,
  imports: [OnyxIconComponent, TranslateModule],
  templateUrl: './unavailability-file-cell.component.html',
  styleUrl: './unavailability-file-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnavailabilityFileCellComponent {
  protected readonly I18N = 'unavailabilities';

  public file = input.required<File | string | null>();
  public showFile = input<(file: File | string) => void>();
}
