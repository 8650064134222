import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { omit } from 'lodash';
import { map, Observable } from 'rxjs';
import { PartialDeep } from 'type-fest';
import { FleetValidation } from '../../../../common/interfaces/validation/fleet-validation';
import { CacheService } from '../../../../common/services/cache.service';
import { ValidationService } from '../../../../common/services/validation.service';
import { FleetForm } from '../../fleet-form/fleet-form.component';
import { FLEET_DUPLICATE_OMIT_KEYS } from '../constants/fleet-duplicate-omit-keys';
import { FleetRouterStateKey } from '../enums/fleet-router-state-key';
import { FleetStorageKey } from '../enums/fleet-storage-key';
import { Fleet } from '../interfaces/fleet';

export const addFleetResolver: ResolveFn<
  Observable<{
    validation: FleetValidation;
    form: PartialDeep<FleetForm>;
  }>
> = () => {
  const router = inject(Router);
  const cacheService = inject(CacheService);
  const validationService = inject(ValidationService);

  const state = router.getCurrentNavigation()?.extras?.state;
  const category = state?.[FleetRouterStateKey.CATEGORY];
  const type = state?.[FleetRouterStateKey.TYPE];
  const template: Fleet | undefined = state?.[FleetRouterStateKey.TEMPLATE];

  return validationService.getFleetValidation(category).pipe(
    map((validation) => {
      if (template) {
        return {
          validation,
          form: omit(template, FLEET_DUPLICATE_OMIT_KEYS) as any,
        };
      }

      const cacheKey = `${FleetStorageKey.ADD_FLEET_FORM}-${category}-${type}`;
      const cache = cacheService.get<PartialDeep<FleetForm>>(cacheKey, {
        keepOnNavigation: true,
      });
      if (cache) {
        return {
          validation,
          form: cache,
        };
      }

      return {
        validation,
        form: {
          generalInformation: {
            category,
            type,
          },
        },
      };
    }),
  );
};
