import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxIconComponent } from '../../icons';

@Component({
  selector: 'onyx-tag',
  standalone: true,
  imports: [NgClass, OnyxIconComponent],
  templateUrl: './onyx-tag.component.html',
  styleUrl: './onyx-tag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxTagComponent {
  public color = input.required<
    'gray' | 'light-gray' | 'blue' | 'green' | 'red' | 'light-red'
  >();
  public count = input<number | null>(null);
  public showClose = input(false);
  public disabled = input(false);
}
