import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'onyx-ribbon',
  standalone: true,
  imports: [NgStyle],
  templateUrl: './onyx-ribbon.component.html',
  styleUrl: './onyx-ribbon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxRibbonComponent {
  public marginBottom = input(0);
}
