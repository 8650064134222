import { OnyxComponentInputs, OnyxTableColumn } from '@onyx/angular';
import { StringCellComponent } from '../components/cells/string-cell/string-cell.component';
import { UnavailabilityFileCellComponent } from '../components/cells/unavailability-file-cell/unavailability-file-cell.component';
import { UnavailabilityRangeCellComponent } from '../components/cells/unavailability-range-cell/unavailability-range-cell.component';
import { DictionaryCode } from '../enums/dictionary-code';
import { Unavailability } from '../interfaces/common/unavailability';

export const getUnavailabilitiesColumns = (
  showFile: (file: File | string) => void,
): OnyxTableColumn<Unavailability>[] => {
  return [
    {
      id: 'reason',
      name: 'labels.reason',
      width: 'fill',
      component: {
        ref: StringCellComponent,
        inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
          value: data.item.reason,
          dictionaryCode: DictionaryCode.EMPLOYEE_UNAVAILABILITY_REASON,
          color: 'gray',
        }),
      },
    },
    {
      id: 'range',
      name: 'labels.range',
      width: '200px',
      component: {
        ref: UnavailabilityRangeCellComponent,
        inputs: (
          data,
        ): OnyxComponentInputs<UnavailabilityRangeCellComponent> => ({
          range: data.item.dateRange,
        }),
      },
    },
    {
      id: 'document',
      name: `labels.document`,
      width: '120px',
      component: {
        ref: UnavailabilityFileCellComponent,
        inputs: (
          data,
        ): OnyxComponentInputs<UnavailabilityFileCellComponent> => ({
          file: data.item.file,
          showFile: showFile,
        }),
      },
    },
  ];
};
