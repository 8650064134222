import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime, Settings } from 'luxon';
import { InterfaceLanguage } from '../enums/interface-language';

export const INTERFACE_LANGUAGE_KEY = 'interfaceLanguage';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly language: InterfaceLanguage;

  constructor(private translateService: TranslateService) {
    const isLanguage = (language: string): language is InterfaceLanguage =>
      Object.values(InterfaceLanguage).includes(language as InterfaceLanguage);

    const getLanguage = (language: string): InterfaceLanguage | null =>
      isLanguage(language) ? language : null;

    this.language =
      getLanguage(localStorage.getItem(INTERFACE_LANGUAGE_KEY)!) ??
      // TODO: Add when translations ready
      // getLanguage(navigator.language.slice(0, 2)) ??
      // InterfaceLanguage.EN;
      InterfaceLanguage.PL;

    this.setLanguage(this.language);
  }

  public setLanguage(language: InterfaceLanguage): void {
    localStorage.setItem(INTERFACE_LANGUAGE_KEY, language);
    this.translateService.use(this.language);
    this.setLuxonLocale();

    if (language === this.language) return;

    location.reload();
  }

  public getLocale(): string {
    return {
      [InterfaceLanguage.PL]: 'pl-PL',
      [InterfaceLanguage.EN]: 'en-US',
      [InterfaceLanguage.DE]: 'de-DE',
      [InterfaceLanguage.ES]: 'es-ES',
      [InterfaceLanguage.FR]: 'fr-FR',
      [InterfaceLanguage.IT]: 'it-IT',
    }[this.language];
  }

  private setLuxonLocale(): void {
    DateTime.local().setLocale(this.language);
    Settings.defaultLocale = this.language;
  }
}
