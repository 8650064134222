@if (file(); as file) {
  <button
    class="unavailability-file"
    (click)="this.showFile()?.(file); $event.stopPropagation()"
  >
    <onyx-icon name="attachment" [size]="16"></onyx-icon>

    <p class="unavailability-label">{{ I18N + '.excuse' | translate }}</p>
  </button>
} @else {
  <p class="unavailability-label">-</p>
}
