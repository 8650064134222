import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  computed,
  effect,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { isString } from 'lodash';
import { Subject, takeUntil, timer } from 'rxjs';
import { OnyxIcon } from '../../../icons';
import { OnyxIconComponent } from '../../../icons/onyx-icon/onyx-icon.component';
import { OnyxMessageCloseButtonComponent } from '../../../messages/onyx-message/onyx-message-close-button/onyx-message-close-button.component';
import { OnyxToastColor } from '../../enums';
import { OnyxToastMessage } from '../../interfaces';

@Component({
  selector: 'onyx-toast',
  standalone: true,
  imports: [
    OnyxMessageCloseButtonComponent,
    OnyxIconComponent,
    NgClass,
    TranslateModule,
    NgTemplateOutlet,
  ],
  templateUrl: './onyx-toast.component.html',
  styleUrl: './onyx-toast.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxToastComponent implements OnInit, OnDestroy {
  private readonly ANIMATION_DURATION = 300;
  private readonly TOAST_DURATION = 10_000;

  public color = input.required<OnyxToastColor>();
  public message = input.required<OnyxToastMessage>();
  public icon = input<OnyxIcon>();
  public forceClose = input(false);

  public closeToast = output<void>();

  protected fadeOutAnimation = signal(false);

  protected messageAsTemplate = computed<TemplateRef<unknown> | null>(() => {
    const message = this.message();
    return message instanceof TemplateRef ? message : null;
  });
  protected messageAsString = computed<string | null>(() => {
    const message = this.message();
    return isString(message) ? message : null;
  });

  private toastElementRef =
    viewChild.required<ElementRef<HTMLElement>>('toastElement');
  private clear$ = new Subject<void>();

  constructor() {
    effect(
      () => {
        if (this.forceClose()) this.close();
      },
      { allowSignalWrites: true },
    );
  }

  public ngOnInit(): void {
    this.startTimer();
  }

  public ngOnDestroy(): void {
    this.clear$.next();
    this.clear$.complete();
  }

  protected startTimer(): void {
    this.clearTimer();

    timer(this.TOAST_DURATION)
      .pipe(takeUntil(this.clear$))
      .subscribe(() => this.close());
  }

  protected clearTimer(): void {
    this.clear$.next();
  }

  protected close(): void {
    this.clearTimer();

    this.updateAnimationHeight();
    this.fadeOutAnimation.set(true);

    timer(this.ANIMATION_DURATION).subscribe(() => this.closeToast.emit());
  }

  private updateAnimationHeight(): void {
    const toastElement = this.toastElementRef().nativeElement;
    const height = toastElement.offsetHeight;
    toastElement.style.setProperty('--height', `${height}px`);
  }
}
