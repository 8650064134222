import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OnyxOption } from '@onyx/angular';
import { forkJoin, map, Observable } from 'rxjs';
import { AuthService } from '../../auth/common/services/auth.service';
import { FleetCategory } from '../../dashboard/fleet/common/enums/fleet-category';
import { EmployeeRole } from '../../dashboard/management-panel/employees/common/enums/employee-role';
import {
  DictionaryCode,
  DictionaryValueByCode,
} from '../enums/dictionary-code';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private translateService: TranslateService,
    private authService: AuthService,
  ) {
    super(http);
  }

  public getDictionary<T extends DictionaryCode>(
    code: T,
  ): Observable<DictionaryValueByCode[T][]> {
    const language =
      this.translateService.currentLang ?? this.translateService.defaultLang;

    return this.get(`/dictionaries/${code}/${language}`);
  }

  public getFleetCategories(): Observable<OnyxOption<FleetCategory>[]> {
    return forkJoin([
      this.getDictionary(DictionaryCode.VEHICLE_CATEGORY),
      this.getDictionary(DictionaryCode.TRAILER_CATEGORY),
    ]).pipe(map((categories) => categories.flat()));
  }

  public getVatIdCountries(): Observable<OnyxOption<string>[]> {
    return this.getDictionary(DictionaryCode.COUNTRY).pipe(
      map((countries) =>
        countries.map((country) => ({
          ...country,
          name: country.value.toUpperCase(),
        })),
      ),
    );
  }

  public getAvailableEmployeeRoles(): Observable<
    DictionaryValueByCode[DictionaryCode.EMPLOYEE_ROLE][]
  > {
    return this.getDictionary(DictionaryCode.EMPLOYEE_ROLE).pipe(
      map((roles) => {
        const userRoles = this.authService.user()?.roles;
        const isAdmin = userRoles?.includes(EmployeeRole.BUSINESS_ADMIN);
        const isManager = isAdmin || userRoles?.includes(EmployeeRole.MANAGER);

        return roles.filter((role) => {
          if (role.value === EmployeeRole.BUSINESS_ADMIN) return isAdmin;
          if (role.value === EmployeeRole.MANAGER) return isAdmin;
          return isManager;
        });
      }),
    );
  }
}
