@if (range(); as range) {
  <div class="unavailability-range">
    <onyx-icon name="clock-empty" [size]="16"></onyx-icon>

    <p class="f-medium-2">
      {{ range.from! | onyxDate: 'date-dot' : range.to! }}
    </p>
  </div>
} @else {
  -
}
