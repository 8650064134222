export class OverflowHelper {
  public static enableOverflowTooltip(element: HTMLElement): boolean {
    const clonedElement = element.cloneNode(true) as HTMLElement;

    const computedStyles = window.getComputedStyle(element);
    for (const [property, value] of Object.entries(computedStyles)) {
      clonedElement.style.setProperty(property, value);
    }

    clonedElement.style.width = 'max-content';
    clonedElement.style.position = 'fixed';
    clonedElement.style.visibility = 'hidden';

    (element.parentNode ?? document.body).append(clonedElement);

    const isOverflowing = element.clientWidth < clonedElement.clientWidth;
    setTimeout(() => clonedElement.remove());

    return isOverflowing;
  }
}
