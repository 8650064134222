import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FLAGS } from '../constants';
import { OnyxCustomIconComponent } from '../onyx-custom-icon/onyx-custom-icon.component';

@Component({
  selector: 'onyx-flag',
  standalone: true,
  imports: [OnyxCustomIconComponent],
  templateUrl: './onyx-flag.component.html',
  styleUrl: './onyx-flag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxFlagComponent {
  public countryCode = input.required<(typeof FLAGS)[number]>();
}
