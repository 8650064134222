import { InjectionToken } from '@angular/core';
import { ITranslationResource } from 'ngx-translate-multi-http-loader';

export const ONYX_TRANSLATIONS_SOURCE: ITranslationResource = {
  prefix: 'i18n/onyx-angular/',
  suffix: '.json',
};

export const ONYX_HERE_MAPS_API_KEY = new InjectionToken<string>(
  'HERE Maps API Key',
);

export const ONYX_TOOLTIP_DELAY = 500;
export const ONYX_TRANSITION_DURATION = 300;
