import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const onyxPhoneValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  if (!control.value) return null;

  const phoneNumber = `${control.value.areaCode}${control.value.number}`;
  return isValidPhoneNumber(phoneNumber) ? null : { phone: true };
};
