import {
  OnyxComponentInputs,
  OnyxFormMode,
  OnyxTableColumn,
} from '@onyx/angular';
import { ContractorDocumentsFormInformationCellComponent } from '../cells/contractor-documents-form-information-cell/contractor-documents-form-information-cell.component';
import { ContractorDocumentsFormScanCellComponent } from '../cells/contractor-documents-form-scan-cell/contractor-documents-form-scan-cell.component';
import { ContractorDocument } from '../interfaces/contractor-documents';

export const contractorsFormDocumentsColumns = (
  mode: OnyxFormMode,
): OnyxTableColumn<ContractorDocument>[] => {
  return [
    {
      id: 'documentWithDate',
      name: 'labels.documentWithDate',
      width: 'fill',
      component: {
        ref: ContractorDocumentsFormInformationCellComponent,
        inputs: (
          data,
        ): OnyxComponentInputs<ContractorDocumentsFormInformationCellComponent> => ({
          document: data.item,
          mode,
        }),
      },
    },
    {
      id: 'scan',
      name: 'labels.documentScan',
      width: '330px',
      component: {
        ref: ContractorDocumentsFormScanCellComponent,
        inputs: (
          data,
        ): OnyxComponentInputs<ContractorDocumentsFormScanCellComponent> => ({
          scan: data.item.scan,
        }),
      },
    },
  ];
};
