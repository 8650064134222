import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxIconComponent } from '../../../icons';

@Component({
  selector: 'onyx-message-close-button',
  standalone: true,
  imports: [OnyxIconComponent],
  templateUrl: './onyx-message-close-button.component.html',
  styleUrl: './onyx-message-close-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxMessageCloseButtonComponent {
  public color = input.required<'green' | 'red' | 'gray' | 'yellow'>();
}
