<!-- eslint-disable @angular-eslint/template/elements-content -->
<button
  class="toggle {{ size() }}"
  [ngClass]="{
    active: value(),
    disabled: disabled(),
    invalid: isInvalid(),
  }"
  type="button"
  [disabled]="disabled()"
  [tabindex]="disabled() ? -1 : 0"
  (focusout)="onTouched?.()"
></button>

@if (label(); as label) {
  <onyx-input-label
    type="title"
    [label]="label"
    [optional]="!!formControl() && isOptional() && showOptional()"
    [disabled]="disabled()"
  ></onyx-input-label>
}

@if (formControl() && showErrors()) {
  <onyx-form-control-error
    [formControls]="[formControl()!]"
    type="toggle"
  ></onyx-form-control-error>
}
