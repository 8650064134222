import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  ElementRef,
  forwardRef,
  Injector,
  input,
  model,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import parsePhoneNumberFromString, { AsYouType } from 'libphonenumber-js';
import { BehaviorSubject, filter, shareReplay } from 'rxjs';
import { OnyxDropdownDirective } from '../../../directives';
import { OnyxDropdownOption } from '../../../directives/interfaces';
import { OnyxOverlayPosition } from '../../../enums';
import { OnyxPhone } from '../../../interfaces/onyx-phone';
import { OnyxBaseFormControlComponent } from '../../../internal/components/onyx-base-form-control/onyx-base-form-control.component';
import { OnyxFormControlErrorComponent } from '../../../internal/components/onyx-form-control-error/onyx-form-control-error.component';
import { OnyxFilterPipe, OnyxHighlightPipe } from '../../../pipes';
import { OnyxDictionariesService } from '../../../services';
import { OnyxPreferencesService } from '../../../services/onyx-preferences.service';
import { OnyxFlagComponent, OnyxIconComponent } from '../../icons';
import { OnyxInputLabelComponent } from '../../labels';
import { OnyxTooltipContext } from '../../tooltip';

type Country = OnyxDropdownOption<string> & { areaCode: string };

@Component({
  selector: 'onyx-phone-number-input',
  standalone: true,
  imports: [
    OnyxInputLabelComponent,
    NgClass,
    OnyxDropdownDirective,
    AsyncPipe,
    OnyxIconComponent,
    OnyxFormControlErrorComponent,
    OnyxFlagComponent,
    FormsModule,
    OnyxHighlightPipe,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnyxPhoneNumberInputComponent),
      multi: true,
    },
    {
      provide: OnyxFilterPipe,
      useClass: OnyxFilterPipe,
    },
  ],
  templateUrl: './onyx-phone-number-input.component.html',
  styleUrl: './onyx-phone-number-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxPhoneNumberInputComponent
  extends OnyxBaseFormControlComponent
  implements ControlValueAccessor, OnInit
{
  protected readonly OnyxOverlayPosition = OnyxOverlayPosition;
  protected readonly countries$ = this.dictionaryService
    .getDictionary('country')
    .pipe(
      filter((countries): countries is Country[] => countries != null),
      shareReplay(1),
    );

  public label = input<string>();
  public hint = input<OnyxTooltipContext>();
  public disabled = model(false);
  public showErrors = input(true);

  protected areaCode = signal<string | null>('+48');
  protected phoneNumber = signal<string | null>(null);
  protected expanded = signal(false);
  protected query = signal('');
  protected selectedOptions = signal<Country[]>([]);
  protected onChange?: (value: OnyxPhone | null) => void;
  protected onTouched?: () => void;

  protected inputElementRef =
    viewChild.required<ElementRef<HTMLInputElement>>('inputElement');

  private _options$ = new BehaviorSubject<Country[] | null>(null);
  protected get options$() {
    return this._options$.asObservable();
  }

  constructor(
    protected override injector: Injector,
    protected override destroyRef: DestroyRef,
    private dictionaryService: OnyxDictionariesService,
    private preferencesService: OnyxPreferencesService,
    private filterPipe: OnyxFilterPipe,
  ) {
    super(injector, destroyRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.setSelectedOption(this.preferencesService.defaultAreaCode());

    effect(
      () => {
        const number = this.phoneNumber();
        const areaCode = this.areaCode();

        this.onChange?.(number && areaCode ? { number, areaCode } : null);
      },
      { allowSignalWrites: true, injector: this.injector },
    );

    effect(
      () => {
        const query = this.query();

        this.countries$.subscribe((countries) => {
          this._options$.next(this.filterOptions(countries, query));
        });
      },
      { allowSignalWrites: true, injector: this.injector },
    );
  }

  public registerOnChange(fn: (value: OnyxPhone | null) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled.set(disabled);
  }

  public writeValue(value: OnyxPhone | null): void {
    const defaultAreaCode = this.preferencesService.defaultAreaCode();
    this.areaCode.set(value?.areaCode ?? defaultAreaCode);
    this.phoneNumber.set(value?.number ?? null);
    this.setSelectedOption(value?.areaCode ?? defaultAreaCode);
  }

  public focus(): void {
    if (this.disabled()) return;
    this.inputElementRef().nativeElement.focus();
  }

  protected pasteNumber(event: ClipboardEvent): void {
    event.preventDefault();

    const target = event.target as HTMLInputElement;
    const clipboardData = event.clipboardData?.getData('text') ?? '';

    if (!clipboardData) return;

    const sanitizedData = clipboardData.replace(/[()]/g, '');
    const phoneNumber = parsePhoneNumberFromString(sanitizedData);

    if (phoneNumber) {
      const country = phoneNumber.country;
      const callingCode = `+${phoneNumber.countryCallingCode}`;
      const formattedNumber = new AsYouType(country).input(sanitizedData);
      const nationalNumber = formattedNumber.slice(callingCode.length).trim();

      target.value = nationalNumber;

      this.setSelectedOption(callingCode);
      this.areaCode.set(callingCode);
      this.phoneNumber.set(nationalNumber);
    } else {
      target.value = sanitizedData;
      this.phoneNumber.set(sanitizedData);
    }
  }

  protected handleValueChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.phoneNumber.set(value);
    this.inputElementRef().nativeElement.value = value;
  }

  protected changeSelectedOptions(options: Country[]): void {
    this.selectedOptions.set(options);
    this.areaCode.set(options[0].areaCode);
  }

  private filterOptions(options: Country[], query: string): Country[] {
    return this.filterPipe.transform(options, query, [
      'name',
      'areaCode',
      'value',
    ]);
  }

  private setSelectedOption(areaCode: string): void {
    this.countries$.subscribe((countries) => {
      const option = countries.find((country) => country.areaCode === areaCode);
      if (option) this.selectedOptions.set([option]);
    });
  }
}
