import { DatePipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  model,
  output,
} from '@angular/core';
import { OnyxIconComponent } from '../../../../icons';
import { CalendarRole, CalendarView } from '../../../enums';

@Component({
  selector: 'onyx-datepicker-calendar-headbar',
  standalone: true,
  imports: [NgClass, OnyxIconComponent, DatePipe],
  templateUrl: './onyx-datepicker-calendar-headbar.component.html',
  styleUrl: './onyx-datepicker-calendar-headbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxDatepickerCalendarHeadbarComponent {
  protected readonly CalendarView = CalendarView;
  protected readonly CalendarRole = CalendarRole;

  public view = model.required<CalendarView>();
  public date = input.required<string>();
  public allowOpenChange = input(true);
  public calendarRole = input(CalendarRole.FROM);

  public changeMonth = output<-1 | 1>();

  protected changeView(view: CalendarView): void {
    if (!this.allowOpenChange()) return;

    this.view.update((currentView) =>
      currentView !== view ? view : CalendarView.CALENDAR,
    );
  }
}
