import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { OnyxBaseFormControlComponent } from '../../../../internal/components/onyx-base-form-control/onyx-base-form-control.component';
import { I18N_NAMESPACE, INTEGER_REGEXP } from '../../../../internal/constants';
import { OnyxClearButtonComponent } from '../../../buttons';
import { OnyxCheckboxComponent } from '../../../checkbox';
import { OnyxIconComponent } from '../../../icons';
import { OnyxInputLabelComponent } from '../../../labels';
import { OnyxTooltipContext } from '../../../tooltip';

const DATE_REGEXP = /20[0-9]{2}-[0-1][0-9]-[0-3][0-9]/;

@Component({
  selector: 'onyx-datepicker-input',
  standalone: true,
  imports: [
    NgClass,
    OnyxInputLabelComponent,
    OnyxClearButtonComponent,
    OnyxIconComponent,
    OnyxCheckboxComponent,
    TranslateModule,
  ],
  templateUrl: './onyx-datepicker-input.component.html',
  styleUrl: './onyx-datepicker-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxDatepickerInputComponent extends OnyxBaseFormControlComponent {
  protected readonly I18N = `${I18N_NAMESPACE}.datepicker`;

  public label = input<string | boolean>();
  public hint = input<OnyxTooltipContext>();
  public showIndefinite = input.required<boolean>();
  public indefiniteLabel = input<string>();
  public disabled = input.required<boolean>();

  public inputClick = output<Event>();
  public inputFocusin = output<Event>();
  public inputFocusout = output<Event>();
  public changeValue = output<{
    date: string | null;
    indefinite: boolean | null;
  }>();

  public inputElementRef = viewChild.required<ElementRef>('inputElement');

  protected value = signal<{
    year?: number;
    month?: number;
    day?: number;
    indefinite?: boolean;
  }>({});

  public setValue(date?: string | null, indefinite?: boolean): void {
    if (!date) {
      this.value.set({ indefinite });
      this.clearInput();
      return;
    }

    const parsedDate = DateTime.fromISO(date);

    const value = {
      year: parsedDate.year,
      month: parsedDate.month,
      day: parsedDate.day,
      indefinite: !!indefinite,
    };

    this.value.set(value);
    this.updateDateInputs(value);
  }

  public focusInputs(event: Event): void {
    event.stopPropagation();
    const input = event.target as HTMLInputElement;
    if (input.nodeName === 'INPUT' && !!input.value) {
      input.setSelectionRange(0, input.value.length);
      return;
    }

    const value = this.value();
    if (!value.day) {
      this.focusInputElement(0);
    } else if (!value.month) {
      this.focusInputElement(2);
    } else if (!value.year) {
      this.focusInputElement(4);
    } else {
      this.focusInputElement(0);
    }
  }

  public focusDayInput(): void {
    this.focusInputElement(0);
  }

  public focus(
    value: number | undefined = undefined,
    index: 0 | 2 | 4 = 0,
  ): void {
    if (!value) this.focusInputElement(index);
  }

  protected clearValue(): void {
    this.value.set({});
    this.changeDate();
    this.clearInput();
  }

  protected onfocusout(event: Event, value?: number | boolean): void {
    if (!value) return;

    event.stopPropagation();
  }

  protected changeDay(event: Event): void {
    const input = event.target as HTMLInputElement;
    const data = (event as InputEvent).data!;
    this.value().day = Number(input.value);

    if (Number(input.value) > 31) {
      input.value = `0${data}`;
      if (Number(data) > 3) {
        this.focusInputElement(2);
      }
    } else if (input.value.length === 1) {
      if (data && data !== '0') {
        input.value = `0${data}`;
        if (Number(data) > 3) {
          this.focusInputElement(2);
        }
      }
    } else if (input.value.length > 1 && Number(input.value) < 1) {
      input.value = '01';
      this.focusInputElement(2);
    } else if (data) {
      if (input.value.length > 2) {
        input.value = `${input.value[1]}${data}`;
      }
      this.focusInputElement(2);
    }

    this.value().day = Number(input.value);
    this.changeDate();
  }

  protected changeMonth(event: Event): void {
    const input = event.target as HTMLInputElement;
    const data = (event as InputEvent).data!;
    this.value().month = Number(input.value);

    if (Number(input.value) > 12) {
      input.value = `0${data}`;
      if (Number(data) > 1) {
        this.focusInputElement(4);
      }
    } else if (input.value.length === 1) {
      if (data && data !== '0') {
        input.value = `0${data}`;
        if (Number(data) > 1) {
          this.focusInputElement(4);
        }
      }
    } else if (input.value.length > 1 && Number(input.value) < 1) {
      input.value = '01';
      this.focusInputElement(4);
    } else if (data) {
      if (input.value.length > 2) {
        input.value = `${input.value[1]}${data}`;
      }
      this.focusInputElement(4);
    } else {
      this.focusInputElement(0);
    }

    this.value().month = Number(input.value);
    this.changeDate();
  }

  protected changeYear(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.value.length < 4) {
      this.value().year = undefined;
      if (input.value.length === 0) this.focusInputElement(2);
    } else {
      this.value().year = Number(input.value);
    }

    this.changeDate();
  }

  protected previousInput(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (!input.value) {
      (
        input.previousElementSibling?.previousElementSibling as HTMLElement
      ).focus();
    }
  }

  protected validateInput(event: KeyboardEvent): boolean {
    if (INTEGER_REGEXP.test(event.key)) return true;

    event.preventDefault();
    return false;
  }

  protected pasteDate(event: ClipboardEvent): boolean {
    const value = event.clipboardData?.getData('text');
    if (!value || !DATE_REGEXP.test(value)) return false;

    this.setValue(value);
    this.changeDate();
    setTimeout(() => {
      this.focusInputElement(4);
    });
    return false;
  }

  protected changeIndefinite(indefinite: boolean): void {
    this.value.set({
      day: undefined,
      month: undefined,
      year: undefined,
      indefinite,
    });
    this.changeValue.emit({
      date: null,
      indefinite,
    });
    this.clearInput();
  }

  private changeDate(): void {
    const { year, month, day, indefinite } = this.value();
    if (!year || !month || !day) {
      this.changeValue.emit({
        date: null,
        indefinite: this.showIndefinite() && !!indefinite,
      });
      return;
    }

    const date = DateTime.fromObject({ year, month, day }).toISODate();

    this.value.set({
      year,
      month,
      day,
      indefinite: this.showIndefinite() ? false : undefined,
    });
    this.changeValue.emit({
      date,
      indefinite: this.value().indefinite ?? null,
    });
  }

  private updateDateInputs(date: {
    year: number;
    month: number;
    day: number;
  }): void {
    const inputs = this.getInputElements();
    const month = String(date.month);
    const day = String(date.day);
    inputs[0].value = day.length === 1 ? `0${day}` : day;
    inputs[2].value = month.length === 1 ? `0${month}` : month;
    inputs[4].value = String(date.year);
  }

  private clearInput(): void {
    const inputs = this.getInputElements();
    inputs[0].value = '';
    inputs[2].value = '';
    inputs[4].value = '';
  }

  private focusInputElement(index: 0 | 2 | 4): void {
    const input = this.getInputElements()[index];
    input.focus();
    input.setSelectionRange(0, input.value.length);
  }

  private getInputElements(): HTMLInputElement[] {
    return this.inputElementRef().nativeElement.firstElementChild!
      .children as HTMLInputElement[];
  }
}
