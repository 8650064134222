import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import {
  ComponentRef,
  ElementRef,
  Injectable,
  InjectionToken,
  Signal,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { filter, Observable, takeUntil } from 'rxjs';
import {
  OnyxBaseDropdownConfig,
  SubdropdownOverlayConfig,
} from '../../directives/interfaces';
import { OnyxOverlayPosition } from '../../enums';
import { OnyxOverlayService } from './onyx-overlay.service';

@Injectable({
  providedIn: 'root',
})
export class OnyxDropdownOverlayService {
  private readonly VIEWPORT_MARGIN = 4;

  private readonly overlayService: OnyxOverlayService;

  public detach$: Observable<void>;
  public componentRef: Signal<ComponentRef<unknown> | null>;

  private intersectionObserver?: IntersectionObserver;
  private subDropdowns: SubdropdownOverlayConfig[] = [];

  constructor(private overlay: Overlay) {
    this.overlayService = new OnyxOverlayService(this.overlay);
    this.detach$ = this.overlayService.detach$;
    this.componentRef = this.overlayService.componentRef;
  }

  public attachDropdown<T extends OnyxBaseDropdownConfig, CT>(
    sourceRef: ElementRef,
    viewContainerRef: ViewContainerRef,
    component: Type<CT>,
    injectionToken: InjectionToken<T>,
    config: T,
  ): void {
    this.detachDropdown();

    this.createOverlayWithPositions(sourceRef, config);
    this.createDropdownAndAttachToOverlay(
      viewContainerRef,
      component,
      injectionToken,
      config,
    );

    this.intersectionObserver = this.hideOnNotIntersecting(
      sourceRef,
      this.overlayService,
    );
    this.detachDropdownOnClickOutside(sourceRef);
  }

  public detachDropdown(): void {
    this.overlayService.detachFromOverlay();
    this.intersectionObserver?.disconnect();
    this.intersectionObserver = undefined;
  }

  public attachSubDropdown<T extends OnyxBaseDropdownConfig, CT>(
    sourceRef: ElementRef,
    viewContainerRef: ViewContainerRef,
    component: Type<CT>,
    injectionToken: InjectionToken<T>,
    config: T,
  ): void {
    this.detachSubDropdown();

    const positionStrategy = this.overlayService.getDefaultPositionStrategy(
      sourceRef.nativeElement,
      [
        OnyxOverlayService.positionStrategyRight,
        OnyxOverlayService.positionStrategyLeft,
      ],
      this.VIEWPORT_MARGIN,
    );
    const overlayRef = this.overlayService.createOverlayRef({
      positionStrategy,
      scrollStrategy: this.overlayService.scrollStrategies.reposition({
        scrollThrottle: 0,
      }),
    });
    const dropdownInjector = this.overlayService.createComponentInjector(
      injectionToken,
      config,
    );
    const dropdownPortal = this.overlayService.createComponentPortal(
      component,
      dropdownInjector,
      viewContainerRef,
    );
    overlayRef.attach(dropdownPortal);

    const intersectionObserver = this.hideOnNotIntersecting(
      sourceRef,
      overlayRef,
    );

    this.subDropdowns.push({
      overlayRef,
      intersectionObserver,
    });
  }

  public detachSubDropdown(): void {
    const subDropdown = this.subDropdowns.pop();
    if (!subDropdown?.overlayRef.hasAttached()) return;

    subDropdown.overlayRef.detach();
    subDropdown.intersectionObserver.disconnect();
  }

  private createOverlayWithPositions(
    sourceRef: ElementRef,
    config: OnyxBaseDropdownConfig,
  ): void {
    const offsetY = config.targetRef
      ? sourceRef.nativeElement.getBoundingClientRect().top -
        config.targetRef.getBoundingClientRect().top
      : 0;
    const positions = this.getOverlayPositions(config, offsetY).map(
      (position) => {
        (position.panelClass as string[]).push(...config.hostCss);
        return { ...position, ...config.overridePositions };
      },
    );

    const positionStrategy = this.overlayService.getDefaultPositionStrategy(
      config.targetRef ?? sourceRef.nativeElement,
      positions,
      this.VIEWPORT_MARGIN,
    );
    this.overlayService.createOverlay(sourceRef, {
      positionStrategy,
      scrollStrategy: this.overlayService.scrollStrategies.reposition({
        scrollThrottle: 0,
      }),
      hasBackdrop: config.showBackdrop,
      backdropClass: 'dropdown-backdrop',
    });
  }

  private createDropdownAndAttachToOverlay<T>(
    viewContainerRef: ViewContainerRef,
    component: Type<any>,
    injectionToken: InjectionToken<T>,
    config: T,
  ): void {
    const dropdownInjector = this.overlayService.createComponentInjector(
      injectionToken,
      config,
    );
    const dropdownPortal = this.overlayService.createComponentPortal(
      component,
      dropdownInjector,
      viewContainerRef,
    );
    this.overlayService.attachToOverlay(dropdownPortal);
  }

  private hideOnNotIntersecting(
    elementRef: ElementRef,
    target: OnyxOverlayService | OverlayRef,
  ): IntersectionObserver {
    const intersectionObserver = new IntersectionObserver(([element]) => {
      if (element.isIntersecting) {
        target.removePanelClass('hidden');
      } else {
        target.addPanelClass('hidden');
      }
    });
    intersectionObserver.observe(elementRef.nativeElement);

    return intersectionObserver;
  }

  private detachDropdownOnClickOutside(sourceRef: ElementRef): void {
    this.overlayService.outsidePointerEvents$
      ?.pipe(
        filter((event) => !sourceRef.nativeElement.contains(event.target)),
        takeUntil(this.overlayService.detach$),
      )
      .subscribe(() => this.detachDropdown());
  }

  private getOverlayPositions(
    config: OnyxBaseDropdownConfig,
    offsetY = 0,
  ): ConnectedPosition[] {
    let positions = config.positions;
    if (!positions?.length) {
      positions = [OnyxOverlayPosition.BOTTOM, OnyxOverlayPosition.TOP];
    }

    return positions
      .map(
        (position) =>
          ({
            [OnyxOverlayPosition.TOP]: {
              ...OnyxOverlayService.positionStrategyTop,
              originX: 'start',
              overlayX: 'start',
              panelClass: ['top', ...config.hostCss],
            } satisfies ConnectedPosition,
            [OnyxOverlayPosition.RIGHT]: {
              ...OnyxOverlayService.positionStrategyRight,
              panelClass: ['right', ...config.hostCss],
            } satisfies ConnectedPosition,
            [OnyxOverlayPosition.BOTTOM]: {
              ...OnyxOverlayService.positionStrategyBottom,
              originX: 'start',
              overlayX: 'start',
              panelClass: ['bottom', ...config.hostCss],
            } satisfies ConnectedPosition,
            [OnyxOverlayPosition.LEFT]: {
              ...OnyxOverlayService.positionStrategyLeft,
              panelClass: ['left', ...config.hostCss],
            } satisfies ConnectedPosition,
          })[position],
      )
      .map((position) => ({
        ...position,
        ...(config.targetRef && {
          originY: 'top',
          overlayY: 'top',
          offsetY,
        }),
      }));
  }
}
