import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { OnyxTooltipDirective, OverflowHelper } from '@onyx/angular';
import { isArray, isString } from 'lodash';
import { DictionaryCode } from '../../../enums/dictionary-code';

@Component({
  selector: 'app-string-cell',
  standalone: true,
  imports: [TranslateModule, NgTemplateOutlet, OnyxTooltipDirective, NgClass],
  templateUrl: './string-cell.component.html',
  styleUrl: './string-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StringCellComponent {
  protected readonly OverflowHelper = OverflowHelper;

  public value = input<string[] | string | null>();
  public dictionaryCode = input<DictionaryCode>();
  public color = input<'gray' | 'transparent'>('transparent');
  public wrap = input(false);

  protected valueAsArray = computed(() => {
    const value = this.value();
    return isArray(value) && value.length !== 0 ? value : false;
  });

  protected valueAsString = computed(() => {
    const value = this.value();
    return isString(value) ? value : false;
  });
}
