<ng-container>
  @for (_ of [].constructor(7); track $index) {
    <div class="weekday f-label-2">
      {{ I18N + '.days.' + $index | translate }}
    </div>
  }

  <ng-container>
    @let selectedDates = dateRange();
    @let currentMonth = DateTime.fromISO(calendar()[0]).month;
    @for (day of getCalendarDays(); track DateTime.fromISO(day)) {
      <button
        class="calendar-day"
        [ngClass]="{
          'previous-month': DateTime.fromISO(day).month < currentMonth,
          'next-month': DateTime.fromISO(day).month > currentMonth,
          today: DateTime.fromISO(day).toISODate() === today(),
          active:
            (DateTime.fromISO(day).toISODate() === selectedDates.from ||
              DateTime.fromISO(day).toISODate() === selectedDates.to) &&
            DateTime.fromISO(day).month === currentMonth,
          'active-range':
            selectedDates.from &&
            selectedDates.to &&
            DateTime.fromISO(selectedDates.from).isValid &&
            DateTime.fromISO(selectedDates.to).isValid &&
            DateTime.fromISO(day).toISODate()! > selectedDates.from &&
            DateTime.fromISO(day).toISODate()! < selectedDates.to &&
            DateTime.fromISO(day).month === currentMonth,
        }"
        [disabled]="disableDate()(selectedDates, day)"
        (click)="changeDate.emit(day)"
      >
        {{ DateTime.fromISO(day).day }}
      </button>
    }
  </ng-container>
</ng-container>
