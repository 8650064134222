<onyx-modal
  type="center"
  size="m"
  heading="{{ I18N + '.wantBlock' | translate }} 
  {{ contractor.companyProfile.displayName }}?"
  [close$]="close$"
  (keydown.enter)="submit()"
  (closeModal)="dialogRef.close($event)"
>
  <div class="container">
    <onyx-message type="warning" size="s" [borderRadius]="true">
      {{ I18N + '.blockInformation' | translate }}
    </onyx-message>

    <form [formGroup]="form">
      <onyx-tabs
        formControlName="reason"
        [label]="I18N + '.blockReason' | translate"
        [tabs]="contractorBlockReason$ | async"
        size="m"
      ></onyx-tabs>

      <onyx-text-field
        formControlName="reasonDescription"
        [label]="I18N + '.blockComment' | translate"
      ></onyx-text-field>
    </form>
  </div>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next(false)">
      {{ 'buttons.cancel' | translate }}
    </onyx-button>

    <onyx-button
      type="primary"
      color="red"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{ 'buttons.block' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>
