<div class="container">
  <onyx-information-heading>
    {{ 'labels.driverData' | translate }}
  </onyx-information-heading>

  @let driverData = driver().driverData;
  <onyx-information-row [label]="'labels.peselNumber' | translate" size="s">
    {{ driverData.peselNumber }}
  </onyx-information-row>

  <onyx-information-row [label]="'labels.birthDate' | translate" size="s">
    {{ driverData.birthDate! | onyxDate: 'date-dot' }}
  </onyx-information-row>

  <onyx-information-row [label]="'labels.privatePhone' | translate" size="s">
    {{ driverData.privatePhone! | onyxPhone }}

    <div class="copy">
      <onyx-icon-button
        type="transparent"
        size="s"
        color="black"
        (click)="copyPhone(driverData.privatePhone!)"
      >
        <onyx-icon name="copy" [size]="16"></onyx-icon>
      </onyx-icon-button>
    </div>
  </onyx-information-row>

  <onyx-information-row [label]="'labels.nationality' | translate" size="s">
    <app-country-cell [countryCode]="driverData.nationality"></app-country-cell>
  </onyx-information-row>

  <onyx-information-row [label]="'labels.homeAddress' | translate" size="s">
    <app-address
      [address]="driverData.homeAddress"
      [format]="'full'"
      [showFlag]="false"
    ></app-address>
  </onyx-information-row>

  <onyx-information-heading>
    {{ 'forms.countriesWhitelistForm.heading' | translate }}
  </onyx-information-heading>

  <onyx-information-row
    [label]="'forms.countriesWhitelistForm.group' | translate"
    size="s"
  >
    <app-string-cell
      [value]="driver().countries.whitelist"
      [dictionaryCode]="DictionaryCode.COUNTRY"
    ></app-string-cell>
  </onyx-information-row>

  <onyx-information-heading>
    {{ 'labels.skills' | translate }}
  </onyx-information-heading>

  @let skills = driver().skills;
  <onyx-information-row [label]="'labels.languages' | translate" size="s">
    <app-string-cell
      [value]="skills.languages"
      [dictionaryCode]="DictionaryCode.LANGUAGE"
    ></app-string-cell>
  </onyx-information-row>

  <onyx-information-row
    [label]="'labels.driverTechnicalSkills' | translate"
    size="s"
  >
    <app-string-cell
      [value]="skills.technicalSkills"
      [dictionaryCode]="DictionaryCode.DRIVER_TECHNICAL_SKILL"
      [wrap]="true"
    ></app-string-cell>
  </onyx-information-row>

  <onyx-information-row [label]="'labels.semiTrailers' | translate" size="s">
    <app-string-cell
      [value]="skills.semiTrailers"
      [dictionaryCode]="DictionaryCode.VEHICLE_TYPE"
      [wrap]="true"
    ></app-string-cell>
  </onyx-information-row>

  <onyx-information-row
    [label]="'labels.driverExperienceLevel' | translate"
    size="s"
  >
    <app-string-cell
      [value]="skills.experienceLevel"
      [dictionaryCode]="DictionaryCode.DRIVER_EXPERIENCE_LEVEL"
    ></app-string-cell>
  </onyx-information-row>
</div>
