import { FormGroup } from '@angular/forms';

export const onyxNumberRangeValidator =
  (decimalPlaces = 0, fromControlName = 'from', toControlName = 'to') =>
  (form: FormGroup): void => {
    const fromControl = form.get(fromControlName)!;
    const toControl = form.get(toControlName)!;

    const convertValue = (value: number) =>
      decimalPlaces !== 0 ? value / Math.pow(10, decimalPlaces) : value;

    const from = convertValue(fromControl.value);
    const to = convertValue(toControl.value);

    if (from > to) {
      fromControl.setErrors({
        numberRange: to,
      });
    } else {
      fromControl.setErrors(null);
    }
  };
