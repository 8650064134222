import { NgStyle } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import {
  SvgHttpLoader,
  SvgIconComponent,
  SvgIconRegistryService,
  SvgLoader,
} from 'angular-svg-icon';

@Component({
  selector: 'onyx-custom-icon',
  standalone: true,
  imports: [HttpClientModule, SvgIconComponent, NgStyle],
  providers: [
    SvgIconRegistryService,
    { provide: SvgLoader, useClass: SvgHttpLoader },
  ],
  templateUrl: './onyx-custom-icon.component.html',
  styleUrl: './onyx-custom-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxCustomIconComponent {
  public source = input.required<string>();
  public size = input.required<number>();
}
