<div class="heading">
  <div class="content">
    <p class="f-semibold-2">
      <ng-content></ng-content>
    </p>

    <span class="count f-label-1">{{ count() }}</span>
  </div>

  <ng-content select="[action]"></ng-content>
</div>
