@if (amount(); as amount) {
  @let currency = DictionaryCode.CURRENCY + '.' + amount.currency | translate;
  <span>
    {{
      amount.value / 100
        | currency: currency : (short() ? 'symbol' : 'code') : '1.0-2'
    }}
  </span>
} @else {
  -
}
