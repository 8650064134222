import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { OnyxTooltipDirective } from '../../../directives';
import { OverflowHelper } from '../../../helpers/overflow.helper';
import { I18N_NAMESPACE } from '../../../internal/constants';
import { OnyxIconComponent } from '../../icons';
import { OnyxLinkComponent } from '../../links';
import { OnyxTooltipContext } from '../../tooltip';

export interface LabelLink {
  link: string;
  href?: string;
  callback?: () => void;
}

@Component({
  selector: 'onyx-input-label',
  standalone: true,
  imports: [
    NgClass,
    OnyxIconComponent,
    OnyxTooltipDirective,
    TranslateModule,
    RouterLink,
    OnyxLinkComponent,
    TranslateModule,
  ],
  templateUrl: './onyx-input-label.component.html',
  styleUrl: './onyx-input-label.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxInputLabelComponent {
  protected readonly I18N = `${I18N_NAMESPACE}.inputLabel`;
  protected readonly OverflowHelper = OverflowHelper;

  public type = input<'label' | 'title'>('label');
  public label = input.required<string>();
  public optional = input(false);
  public required = input(false);
  public disabled = input(false);
  public hint = input<OnyxTooltipContext>();
  public link = input<LabelLink>();
}
