import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { PartialDeep } from 'type-fest';
import { CacheService } from '../../../../common/services/cache.service';
import { DelegationForm } from '../../delegation-form/delegation-form.component';
import { DelegationsStorageKey } from '../enums/delegations-storage-key';

export const addDelegationResolver: ResolveFn<
  PartialDeep<DelegationForm>
> = () => {
  const cacheService = inject(CacheService);
  const cacheKey = DelegationsStorageKey.ADD_DELEGATION_FORM;
  const cache = cacheService.get<PartialDeep<DelegationForm>>(cacheKey, {
    keepOnNavigation: true,
  });

  if (cache) return cache;

  return {};
};
