import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnyxOption } from '../directives';

@Injectable({
  providedIn: 'root',
})
export abstract class OnyxDictionariesService {
  public abstract getDictionary(code: string): Observable<OnyxOption<string>[]>;
}
