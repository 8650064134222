import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ButtonActionType } from '../../../internal/interfaces';
import { OnyxIconComponent } from '../../icons';

@Component({
  selector: 'onyx-clear-button',
  standalone: true,
  imports: [OnyxIconComponent],
  templateUrl: './onyx-clear-button.component.html',
  styleUrl: './onyx-clear-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxClearButtonComponent {
  public actionType = input<ButtonActionType>('button');
}
