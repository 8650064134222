import { I18N_NAMESPACE } from '../../internal/constants';
import { OnyxTab } from '../tabs';
import { OnyxTableViewMode } from './enums';

const I18N = `${I18N_NAMESPACE}.table.viewMode`;

export const ONYX_TABLE_VIEW_MODE_TABS: OnyxTab<OnyxTableViewMode>[] = [
  {
    icon: { name: 'list-view', size: 16 },
    value: OnyxTableViewMode.LIST,
    tooltip: `${I18N}.${OnyxTableViewMode.LIST}`,
  },
  {
    icon: { name: 'map-view', size: 16 },
    value: OnyxTableViewMode.MAP,
    tooltip: `${I18N}.${OnyxTableViewMode.MAP}`,
  },
] as const;
