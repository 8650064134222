import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const onyxNipNumberValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  if (!control.value) return null;

  const value = String(control.value);
  if (!/^\d{10}$/.test(value)) return { nipNumber: true };

  const WEIGHTS = [6, 5, 7, 2, 3, 4, 5, 6, 7] as const;
  const controlDigit = Number(value.at(-1));

  let sum = 0;
  for (const [index, weight] of WEIGHTS.entries()) {
    sum += Number(value[index]) * weight;
  }

  return sum % 11 !== controlDigit ? { nipNumber: true } : null;
};
