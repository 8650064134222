import { Dialog } from '@angular/cdk/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable, reflectComponentType, Type } from '@angular/core';
import { filter, fromEvent, map, Observable, race, zip } from 'rxjs';
import { OnyxModalComponent } from '../components';
import { OnyxModalConfig } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class OnyxModalService {
  private readonly ONYX_MODAL_TAG_NAME =
    reflectComponentType(OnyxModalComponent)!.selector.toUpperCase();

  constructor(private dialog: Dialog) {}

  public open<Data = unknown, Result = unknown>(
    component: Type<any>,
    data?: Data,
    config?: OnyxModalConfig,
  ): Observable<Result | undefined> {
    const dialogRef = this.dialog.open<Result, Data, any>(component, {
      data,
      disableClose: true,
    });
    const overlayContainerElement = document.querySelector(
      '.cdk-overlay-container',
    )!;
    const dialogElement: HTMLElement =
      dialogRef.componentRef!.location.nativeElement;
    const modalElement = dialogElement.querySelector(this.ONYX_MODAL_TAG_NAME);

    if (config?.disableClose !== true) {
      const escapeKey$ = dialogRef.keydownEvents.pipe(
        filter((event) => event.key === 'Escape'),
        filter((event) => !event.defaultPrevented),
      );

      const mouseDown$ = fromEvent<MouseEvent>(document, 'mousedown');
      const mouseUp$ = fromEvent<MouseEvent>(document, 'mouseup');

      const backdropClick$ = zip(mouseDown$, mouseUp$).pipe(
        map(([down, up]) => [down.target, up.target]),
        filter((targets): targets is Node[] =>
          targets.every((target) => target instanceof Node),
        ),
        filter((targets) =>
          targets.every(
            (target) =>
              !overlayContainerElement.contains(target) ||
              target.parentElement?.tagName === this.ONYX_MODAL_TAG_NAME,
          ),
        ),
      );

      race([escapeKey$, backdropClick$]).subscribe(() => {
        const closeEvent = new CustomEvent('onyxModalClose');
        modalElement?.dispatchEvent(closeEvent);
      });
    }

    if (modalElement?.getAttribute('type') === 'right') {
      dialogRef.config.restoreFocus = false;
      dialogRef.overlayRef.updateScrollStrategy(new NoopScrollStrategy());
    }

    return dialogRef.closed;
  }
}
