import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  DestroyRef,
  Directive,
  ElementRef,
  HostListener,
  TemplateRef,
  ViewContainerRef,
  effect,
  input,
  model,
  output,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { take } from 'rxjs';
import { OnyxPaths } from '../components';
import { OnyxOverlayPosition } from '../enums';
import {
  ONYX_DROPDOWN_CONFIG,
  OnyxDropdownOverlayComponent,
} from '../internal/components/onyx-dropdown-overlay/onyx-dropdown-overlay.component';
import { OnyxDropdownOverlayService } from '../internal/services/onyx-dropdown-overlay.service';
import {
  OnyxDropdownConfig,
  OnyxDropdownOption,
  OnyxDropdownOptions,
} from './interfaces';

@Directive({
  selector: '[onyxDropdownOptions]',
  standalone: true,
})
export class OnyxDropdownDirective<T> {
  public onyxDropdownOptions = input.required<OnyxDropdownOptions<T>>();
  public onyxDropdownCompareKey = input<OnyxPaths<T>>();
  public onyxDropdownValues = input<T[]>();
  public onyxDropdownSelectedOptions = input<OnyxDropdownOption<T>[]>();
  public onyxDropdownMultiple = input(false);
  public onyxDropdownOptional = input(false);
  public onyxDropdownShowSearch = input(false);
  public onyxDropdownQuery = model<string>();
  public onyxDropdownDisabled = input(false);
  public onyxDropdownInheritWidth = input(false);
  public onyxDropdownOptionSize = input(32);
  public onyxDropdownShowAddOption = input(false);
  public onyxDropdownAddOptionMessage = input<string>();
  public onyxDropdownShowCheckbox = input(true);
  public onyxDropdownEmptyMessage = input<string>();
  public onyxDropdownHeaderTemplateRef = input<TemplateRef<any>>();
  public onyxDropdownHeader = input<string>();
  public onyxDropdownOptionTemplateRef = input<TemplateRef<any>>();
  public onyxDropdownFooterTemplateRef = input<TemplateRef<any>>();
  public onyxDropdownAttached = model(false);
  public onyxDropdownShowBackdrop = input(false);
  public onyxDropdownHostCss = input<OnyxDropdownConfig<T>['hostCss']>([
    'fill',
  ]);
  public onyxDropdownPositions = input<OnyxOverlayPosition[]>();
  public onyxDropdownOverridePositions = input<Partial<ConnectedPosition>>();
  public onyxDropdownTargetRef = input<HTMLElement>();

  public onyxDropdownValueChange = output<T | null>();
  public onyxDropdownValuesChange = output<T[]>();
  public onyxDropdownSelectedOptionChange =
    output<OnyxDropdownOption<T> | null>();
  public onyxDropdownSelectedOptionsChange = output<OnyxDropdownOption<T>[]>();
  public onyxDropdownAddOption = output<string>();
  public onyxDropdownFocus = output();

  private attached = signal(false);

  constructor(
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private dropdownOverlayService: OnyxDropdownOverlayService,
    private destroyRef: DestroyRef,
  ) {
    effect(
      () => {
        const attached = this.attached();
        untracked(() => this.onyxDropdownAttached.set(attached));
      },
      { allowSignalWrites: true },
    );

    effect(
      () =>
        this.onyxDropdownAttached()
          ? this.attachDropdown()
          : this.detachDropdown(),
      { allowSignalWrites: true },
    );
  }

  @HostListener('click')
  protected toggle(): void {
    if (this.onyxDropdownDisabled()) return;

    if (this.attached()) {
      this.detachDropdown();
    } else {
      this.show();
    }
  }

  @HostListener('keydown.space', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  protected show(event?: KeyboardEvent): void {
    if (this.attached() || this.onyxDropdownDisabled()) return;

    this.attachDropdown();
    if (this.onyxDropdownShowSearch()) setTimeout(() => this.focusDropdown());

    if (
      this.onyxDropdownShowSearch() ||
      this.onyxDropdownQuery() === undefined
    ) {
      event?.preventDefault();
    }
  }

  @HostListener('keydown.shift.tab')
  @HostListener('keydown.tab')
  protected close(): void {
    this.detachDropdown();
  }

  @HostListener('keydown.arrowdown', ['$event'])
  protected showAndFocus(event: KeyboardEvent): void {
    if (this.onyxDropdownDisabled()) return;

    this.attachDropdown();
    setTimeout(() => this.focusDropdown());

    event.preventDefault();
  }

  private attachDropdown(): void {
    if (this.attached() || this.onyxDropdownDisabled()) return;

    this.dropdownOverlayService.attachDropdown(
      this.elementRef,
      this.viewContainerRef,
      OnyxDropdownOverlayComponent,
      ONYX_DROPDOWN_CONFIG,
      {
        options: this.onyxDropdownOptions(),
        compareKey: this.onyxDropdownCompareKey() as any,
        values: this.onyxDropdownValues(),
        selectedOptions: this.onyxDropdownSelectedOptions(),
        multiple: this.onyxDropdownMultiple(),
        optional: this.onyxDropdownOptional(),
        showSearch: this.onyxDropdownShowSearch(),
        query: this.onyxDropdownQuery,
        width: this.onyxDropdownInheritWidth()
          ? this.elementRef.nativeElement.getBoundingClientRect().width
          : undefined,
        optionSize: this.onyxDropdownOptionSize(),
        showAddOption: this.onyxDropdownShowAddOption(),
        addOptionMessage: this.onyxDropdownAddOptionMessage(),
        showCheckbox: this.onyxDropdownShowCheckbox(),
        emptyMessage: this.onyxDropdownEmptyMessage(),
        headerTemplateRef: this.onyxDropdownHeaderTemplateRef(),
        header: this.onyxDropdownHeader(),
        optionTemplateRef: this.onyxDropdownOptionTemplateRef(),
        footerTemplateRef: this.onyxDropdownFooterTemplateRef(),
        addOption: (query) => this.onyxDropdownAddOption.emit(query),
        changeValue: ({ values, options }) => {
          this.onyxDropdownValueChange.emit(values[0] ?? null);
          this.onyxDropdownValuesChange.emit(values);
          this.onyxDropdownSelectedOptionChange.emit(options[0] ?? null);
          this.onyxDropdownSelectedOptionsChange.emit(options);
        },
        focusElement: () => this.focusElement(),
        close: () => {
          if (this.onyxDropdownShowSearch()) this.onyxDropdownQuery.set('');
          this.detachDropdown();
        },
        showBackdrop: this.onyxDropdownShowBackdrop(),
        hostCss: this.onyxDropdownHostCss(),
        positions: this.onyxDropdownPositions(),
        overridePositions: this.onyxDropdownOverridePositions(),
        targetRef: this.onyxDropdownTargetRef(),
      },
    );

    this.attached.set(true);
    this.dropdownOverlayService.detach$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.attached.set(false));
  }

  private detachDropdown(): void {
    if (!this.attached()) return;

    this.dropdownOverlayService.detachDropdown();
    this.focusElement();
    this.attached.set(false);
  }

  private focusElement(): void {
    if (this.onyxDropdownFocus['listeners'] != null) {
      this.onyxDropdownFocus.emit();
    } else {
      this.elementRef.nativeElement.focus();
    }
  }

  private focusDropdown(): void {
    const dropdown = this.dropdownOverlayService.componentRef()?.instance as
      | OnyxDropdownOverlayComponent<T>
      | undefined;
    dropdown?.focus();
  }
}
