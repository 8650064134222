import { NgClass, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  OnInit,
  computed,
  effect,
  input,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, timer } from 'rxjs';
import { ONYX_TRANSITION_DURATION } from '../../../constants';
import { OnyxAvatarComponent } from '../../avatar';
import { OnyxAvatarCompany, OnyxAvatarUser } from '../../avatar/interfaces';
import { OnyxBadgeComponent } from '../../badges';
import { OnyxBadge } from '../../badges/interfaces';
import { OnyxButtonComponent, OnyxIconButtonComponent } from '../../buttons';
import { OnyxIconComponent } from '../../icons';
import { OnyxLinkComponent } from '../../links';
import { OnyxMessageComponent } from '../../messages';

@Component({
  selector: 'onyx-modal',
  standalone: true,
  imports: [
    OnyxIconButtonComponent,
    OnyxIconComponent,
    OnyxButtonComponent,
    OnyxLinkComponent,
    NgClass,
    NgStyle,
    OnyxMessageComponent,
    OnyxIconButtonComponent,
    RouterModule,
    OnyxAvatarComponent,
    OnyxBadgeComponent,
    OnyxAvatarComponent,
    TranslateModule,
  ],
  templateUrl: './onyx-modal.component.html',
  styleUrl: './onyx-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxModalComponent<T> implements OnInit {
  public type = input.required<'center' | 'right'>();
  public color = input<'white' | 'gray'>('white');
  public size = input<'s' | 'm' | 'l'>('m');
  public heading = input<string>();
  public count = input<number>();
  public avatarUser = input<OnyxAvatarUser>();
  public avatarCompany = input<OnyxAvatarCompany>();
  public roles = input<OnyxBadge<string>[]>();
  public extend = input(false);
  public dictionaryCode = input<string>('');
  public padding = input(false);
  public showClose = input(true);
  public close$ = input<Subject<T>>();

  public closeModal = output<T | undefined>();

  protected isCenter = computed(() => this.type() === 'center');
  protected closeAnimation = signal(false);

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private destroyRef: DestroyRef,
  ) {
    effect(() => {
      const classList = this.elementRef.nativeElement.classList;
      classList.toggle('right', this.type() === 'right');
      classList.toggle('background', this.type() !== 'right');
      classList.toggle('close-animation', this.closeAnimation());
    });
  }

  public ngOnInit(): void {
    this.close$()
      ?.asObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(this.closeDialog.bind(this));
  }

  @HostListener('onyxModalClose')
  protected closeDialog(value?: T): void {
    this.closeAnimation.set(true);
    timer(ONYX_TRANSITION_DURATION)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.closeModal.emit(value));
  }
}
