import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { OnyxModalService } from '@onyx/angular';
import { ContractorsRoute } from '../../contractors.routes';
import { AddContractorModalComponent } from '../components/add-contractor-modal/add-contractor-modal.component';
import { ContractorsRouterStateKey } from '../enums/contractors-router-state-key';

export const addContractorGuard: CanActivateFn = () => {
  const router = inject(Router);
  const modalService = inject(OnyxModalService);

  const state = router.getCurrentNavigation()?.extras?.state;
  const country = state?.[ContractorsRouterStateKey.COUNTRY];
  const vatId = state?.[ContractorsRouterStateKey.VAT_ID];

  if (country && vatId) return true;

  modalService.open(AddContractorModalComponent);
  return router.navigateByUrl(ContractorsRoute.CONTRACTORS_LIST);
};
