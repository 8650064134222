import { OnyxCoordinates } from '../../address-input';
import { OnyxMapEventType, OnyxMapMarkerType } from '../enums';
import { OnyxMapVehicleStatus } from '../enums/onyx-map-vehicle-status';

export type OnyxMapEvent =
  | OnyxMapEvent.AddUpdateMarker
  | OnyxMapEvent.RemoveMarker
  | OnyxMapEvent.AddUpdateVehicle
  | OnyxMapEvent.RemoveVehicle
  | OnyxMapEvent.SelectVehicle
  | OnyxMapEvent.FitContent;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace OnyxMapEvent {
  export class AddUpdateMarker {
    public readonly TYPE = OnyxMapEventType.ADD_UPDATE_MARKER;
    public readonly id: string;
    public readonly type: OnyxMapMarkerType;
    public readonly coordinates: OnyxCoordinates;

    constructor(
      private data: {
        id: string;
        type: OnyxMapMarkerType;
        coordinates: OnyxCoordinates;
      },
    ) {
      ({ id: this.id, type: this.type, coordinates: this.coordinates } = data);
    }
  }

  export class RemoveMarker {
    public readonly TYPE = OnyxMapEventType.REMOVE_MARKER;
    public readonly id: string;

    constructor(private data: { id: string }) {
      ({ id: this.id } = data);
    }
  }

  export class AddUpdateVehicle {
    public readonly TYPE = OnyxMapEventType.ADD_UPDATE_VEHICLE;
    public readonly id: string;
    public readonly status: OnyxMapVehicleStatus;
    public readonly coordinates: OnyxCoordinates;
    public readonly azimuth: number;
    public readonly visible: boolean;

    constructor(
      private data: {
        id: string;
        status: OnyxMapVehicleStatus;
        coordinates: OnyxCoordinates;
        azimuth: number;
        visible: boolean;
      },
    ) {
      ({
        id: this.id,
        status: this.status,
        coordinates: this.coordinates,
        azimuth: this.azimuth,
        visible: this.visible,
      } = data);
    }
  }

  export class RemoveVehicle {
    public readonly TYPE = OnyxMapEventType.REMOVE_VEHICLE;
    public readonly id: string;

    constructor(private data: { id: string }) {
      ({ id: this.id } = data);
    }
  }

  export class SelectVehicle {
    public readonly TYPE = OnyxMapEventType.SELECT_VEHICLE;
    public readonly id: string | null;

    constructor(private data: { id: string | null }) {
      ({ id: this.id } = data);
    }
  }

  export class FitContent {
    public readonly TYPE = OnyxMapEventType.FIT_CONTENT;
  }
}
