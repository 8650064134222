import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxColor } from '../interfaces';

@Component({
  selector: 'onyx-badge',
  standalone: true,
  imports: [],
  templateUrl: './onyx-badge.component.html',
  styleUrl: './onyx-badge.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxBadgeComponent {
  public color = input.required<OnyxColor>();
  public size = input<'s' | 'm'>('m');
}
