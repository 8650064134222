<button
  class="{{ size() }} {{ ICON_BUTTONS[type][color] }}"
  [ngClass]="{
    circle: circle(),
    active: active(),
    selected: selected(),
  }"
  [type]="actionType()"
  [tabindex]="tabindex()"
  [disabled]="disabled()"
>
  <ng-content></ng-content>
</button>
