import { OnyxIcon } from '../icons';
import { OnyxToastColor, OnyxToastType } from './enums';

export const TOAST_SUCCESS_ICON: OnyxIcon = {
  name: 'mark-as-read',
  size: 24,
} as const;

export const TOAST_ERROR_ICON: OnyxIcon = {
  name: 'error-circle-empty',
  size: 24,
} as const;

export const TOAST_INFORMATION_ICON: OnyxIcon = {
  name: 'tooltip',
  size: 24,
} as const;

const COPY_ICON: OnyxIcon = {
  name: 'copy',
  size: 24,
} as const;

export const DEFAULT_TOAST_COLORS: Record<OnyxToastType, OnyxToastColor> = {
  [OnyxToastType.INVALID_DATA]: OnyxToastColor.RED,
  [OnyxToastType.SERVER_ERROR]: OnyxToastColor.RED,
  [OnyxToastType.SAVED_CHANGES]: OnyxToastColor.GREEN,
  [OnyxToastType.COPIED_TO_CLIPBOARD]: OnyxToastColor.GRAY,
  [OnyxToastType.FORM_CHANGES_RESTORED]: OnyxToastColor.GRAY,
} as const;

export const DEFAULT_TOAST_ICONS: Record<OnyxToastType, OnyxIcon> = {
  [OnyxToastType.INVALID_DATA]: TOAST_ERROR_ICON,
  [OnyxToastType.SERVER_ERROR]: TOAST_ERROR_ICON,
  [OnyxToastType.SAVED_CHANGES]: TOAST_SUCCESS_ICON,
  [OnyxToastType.COPIED_TO_CLIPBOARD]: COPY_ICON,
  [OnyxToastType.FORM_CHANGES_RESTORED]: TOAST_INFORMATION_ICON,
} as const;
