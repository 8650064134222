import { HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { OnyxToastService, OnyxToastType } from '@onyx/angular';
import { captureException } from '@sentry/angular-ivy';
import { catchError, map, of } from 'rxjs';
import { PartialDeep } from 'type-fest';
import { Driver } from '../../../drivers/common/interfaces/driver';
import { Fleet } from '../../../fleet/common/interfaces/fleet';
import { DelegationForm } from '../../delegation-form/delegation-form.component';
import { DelegationsRoute } from '../../delegations.routes';
import { DelegationsService } from '../services/delegations.service';

export const editDelegationResolver: ResolveFn<
  PartialDeep<DelegationForm> | null
> = (route: ActivatedRouteSnapshot) => {
  const delegationService = inject(DelegationsService);
  const toastService = inject(OnyxToastService);
  const router = inject(Router);

  const uuid = route.paramMap.get('uuid')!;

  return delegationService.getDelegation(uuid).pipe(
    map(
      (delegation): Partial<DelegationForm> => ({
        driverAndVehicle: {
          primaryDriverUuid: delegation.primaryDriver as Driver,
          secondaryDriverUuid: delegation.secondaryDriver as Driver,
          vehicleUuid: delegation.vehicle as Fleet,
          trailerUuid: delegation.trailer?.uuid ?? null,
        },
        dailyReturns: {
          daysOfWeek: delegation.daysOfWeek,
          hasDailyReturns: delegation.hasDailyReturns,
          hasSelectedDays: delegation.daysOfWeek != null,
        },
        start: {
          departurePlace: delegation.start.address,
          departureDate: delegation.start.date,
          departureTime: delegation.start.time,
        },
        end: {
          arrivalDate: delegation.end.date,
          arrivalPlace: delegation.end.address,
          arrivalTime: delegation.end.time,
        },
      }),
    ),
    catchError((error) => {
      if (error.status === HttpStatusCode.BadRequest) {
        toastService.showError('delegations.delegationNotFound', {
          keepOnNavigation: true,
        });
      } else {
        toastService.showCustom(OnyxToastType.SERVER_ERROR, {
          keepOnNavigation: true,
        });
        if (error.status !== HttpStatusCode.InternalServerError) {
          captureException(error);
        }
      }

      router.navigateByUrl(DelegationsRoute.DELEGATIONS_LIST);
      return of(null);
    }),
  );
};
