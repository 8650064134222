import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Injector,
  input,
  model,
  OnInit,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { isArray, isEqual, orderBy } from 'lodash';
import { I18N_NAMESPACE } from '../../../internal/constants';
import { OnyxChip, OnyxChipsGroup } from '../interfaces';
import { OnyxChipComponent } from '../onyx-chip/onyx-chip.component';

@Component({
  selector: 'onyx-chips',
  standalone: true,
  imports: [OnyxChipComponent, TranslateModule, NgClass],
  templateUrl: './onyx-chips.component.html',
  styleUrl: './onyx-chips.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxChipsComponent<GroupType, ChipType> implements OnInit {
  protected readonly I18N = `${I18N_NAMESPACE}.chips`;

  public chips = input.required({
    transform: (
      chips:
        | OnyxChipsGroup<GroupType, ChipType>[]
        | OnyxChip<ChipType>[]
        | null
        | undefined,
    ): OnyxChipsGroup<GroupType, ChipType>[] => {
      if (chips == null || !isArray(chips) || chips.length === 0) return [];
      if ((chips[0] as any).chips) {
        return chips as OnyxChipsGroup<GroupType, ChipType>[];
      }

      return [
        {
          name: 'default',
          value: 'default' as GroupType,
          chips: chips as OnyxChip<ChipType>[],
        },
      ];
    },
  });
  public single = input(false);
  public selectAll = input<boolean | string>(false);
  public type = input<'primary' | 'secondary'>('primary');
  public wrap = input(false);
  public showGroups = input<boolean>();
  public activeGroup = model<GroupType | null>(null);
  public activeValues = model<ChipType[]>([]);

  protected isSelectedAll = computed(() =>
    isEqual(this.activeValues()?.sort(), this.groupValues()?.sort()),
  );

  private group = computed(
    () =>
      this.chips().find(
        (group) => group.value === (this.activeGroup() ?? 'default'),
      ) ?? this.chips()[0],
  );
  private groupValues = computed(() =>
    this.group().chips.map((chip) => chip.value),
  );

  constructor(private injector: Injector) {}

  public ngOnInit(): void {
    let lastGroup: GroupType | null = null;
    effect(
      () => {
        if (this.chips().length === 0) return;
        if (lastGroup != null && this.activeGroup() === lastGroup) return;

        if (this.activeGroup() == null) {
          this.changeGroup(this.chips()[0].value);
        } else if (this.selectAll()) {
          this.selectAllChips();
        }
        lastGroup = this.activeGroup();
      },
      { allowSignalWrites: true, injector: this.injector },
    );
  }

  protected selectAllChips(): void {
    this.activeValues.set(this.groupValues());
  }

  protected changeGroup(group: GroupType): void {
    const activeGroup = this.activeGroup() ?? 'default';
    if (activeGroup === group) return;

    this.activeGroup.set(group);
    this.activeValues.set(
      this.selectAll() ? this.groupValues() : this.groupValues().slice(0, 1),
    );
  }

  protected toggleChip(chip: OnyxChip<ChipType>, active: boolean): void {
    if (this.single() || (this.isSelectedAll() && this.selectAll())) {
      this.activeValues.set([chip.value]);
    } else if (!active) {
      this.activeValues.update((activeChipsValues) => {
        let values =
          activeChipsValues?.filter((value) => value !== chip.value) ?? null;
        if (!this.single() && this.selectAll() && values?.length === 0) {
          values = this.groupValues();
        }
        return values;
      });
    } else {
      this.activeValues.update((activeChipsValues) =>
        orderBy(
          [...new Set([...(activeChipsValues ?? []), chip.value])],
          (chip) => this.group().chips.findIndex((c) => c.value === chip),
        ),
      );
    }
  }
}
