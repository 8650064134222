import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'fuzzy';
import { get, isArray, isFunction, isString } from 'lodash';
import { OnyxPaths } from '../components';

@Pipe({
  name: 'onyxFilter',
  standalone: true,
})
export class OnyxFilterPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform<T>(
    items: T[],
    query: string,
    extract: OnyxPaths<T>[] | ((item: T) => string) | undefined,
  ): T[] {
    if (!query) return items;

    return filter(query, items, {
      extract: (item) => {
        const value = this.extractValue(item, extract);
        return isString(value) ? this.translateService.instant(value) : value;
      },
    }).map((result) => result.original);
  }

  private extractValue<T>(
    item: T,
    extract: OnyxPaths<T>[] | ((item: T) => string) | undefined,
  ): string {
    if (isArray(extract)) {
      return extract.map((path) => get(item, path) ?? '').join(' ');
    } else if (isFunction(extract)) {
      return extract(item);
    }
    return JSON.stringify(item);
  }
}
