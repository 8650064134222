<button
  class="chip {{ color() }} f-medium-1"
  [ngClass]="{ active: active(), disabled: disabled() }"
>
  <p>{{ chip().name | translate }}</p>

  @if (chip().count != null) {
    <span class="number f-medium-3">{{ chip().count }}</span>
  }
</button>
