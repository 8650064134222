import { HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { OnyxToastService, OnyxToastType } from '@onyx/angular';
import { captureException } from '@sentry/angular-ivy';
import { catchError, map, of } from 'rxjs';
import { PointsOfInterestForm } from '../../points-of-interest-form/points-of-interest-form.component';
import { PointsOfInterestRoute } from '../../points-of-interest.routes';
import { PointsOfInterestService } from '../services/points-of-interest.service';

export const editPointOfInterestResolver: ResolveFn<
  PointsOfInterestForm | null
> = (route: ActivatedRouteSnapshot) => {
  const pointsOfInterestService = inject(PointsOfInterestService);
  const toastService = inject(OnyxToastService);
  const router = inject(Router);

  const uuid = route.paramMap.get('uuid')!;

  return pointsOfInterestService.getPointOfInterest(uuid).pipe(
    map(
      (point): PointsOfInterestForm => ({
        pointInformation: {
          address: point.address,
          name: point.name,
        },
        businessHours: {
          weekday: point.businessHours.weekday,
          saturday: point.businessHours.saturday,
          sunday: point.businessHours.sunday,
        },
        times: {
          averageServiceTime: point.time.averageServiceTime,
        },
        contact: { phone: point.phone },
        note: point.note,
        contractor: {
          selectedContractors: point.contractors.map(
            (contractor) => contractor.contractor,
          ),
          contractors: point.contractors.map((contractor) => ({
            contractor: contractor.contractor,
            gate: contractor.gate,
            uuid: contractor.contractorUuid,
          })),
        },
      }),
    ),
    catchError((error) => {
      if (error.status === HttpStatusCode.BadRequest) {
        toastService.showError('pointsOfInterest.pointNotFound', {
          keepOnNavigation: true,
        });
      } else {
        toastService.showCustom(OnyxToastType.SERVER_ERROR, {
          keepOnNavigation: true,
        });
        if (error.status !== HttpStatusCode.InternalServerError) {
          captureException(error);
        }
      }

      router.navigateByUrl(PointsOfInterestRoute.POINTS_OF_INTEREST_LIST);
      return of(null);
    }),
  );
};
