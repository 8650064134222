import { InjectionToken, WritableSignal } from '@angular/core';
import { OnyxBaseDropdownConfig } from '../../directives/interfaces';

export const ONYX_DATEPICKER_CONFIG = new InjectionToken<OnyxDatepickerConfig>(
  'Datepicker configuration',
);

export interface OnyxDatepickerConfig extends OnyxBaseDropdownConfig {
  date: WritableSignal<OnyxDate>;
  changeDate: (date: OnyxDate | null) => void;
  disableFocusout: () => void;
  showDateRange: boolean;
  showRelativeDays?: boolean;
  yearsRange: OnyxDatepickerYearsRange;
}

export interface OnyxDate {
  date?: string | null;
  from?: string | null;
  to?: string | null;
  indefinite?: boolean;
}

export interface OnyxDatepickerYearsRange {
  past: number;
  future: number;
}
