import { NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  AddressHelper,
  OnyxAddress,
  OnyxFlagComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { DictionaryCode } from '../../enums/dictionary-code';

export type AddressFormat =
  | 'extended'
  | 'full'
  | 'short'
  | 'double-line'
  | 'coordinates';

@Component({
  selector: 'app-address',
  standalone: true,
  imports: [
    UpperCasePipe,
    OnyxFlagComponent,
    TranslateModule,
    OnyxTooltipDirective,
    NgTemplateOutlet,
  ],
  templateUrl: './address.component.html',
  styleUrl: './address.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressComponent {
  protected readonly AddressHelper = AddressHelper;
  protected readonly DictionaryCode = DictionaryCode;

  public address = input.required<OnyxAddress | null>();
  public format = input.required<AddressFormat>();
  public showFlag = input(true);
}
