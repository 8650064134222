import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import {
  ComponentRef,
  InjectionToken,
  Injector,
  ViewContainerRef,
  signal,
} from '@angular/core';
import { Subject } from 'rxjs';

export class OnyxBaseOverlayService {
  private _overlayRef = signal<OverlayRef | null>(null);
  public get hasAttached() {
    return this._overlayRef()?.hasAttached();
  }
  public get overlayRef() {
    return this._overlayRef.asReadonly();
  }

  private _componentRef = signal<ComponentRef<unknown> | null>(null);
  public get componentRef() {
    return this._componentRef.asReadonly();
  }

  private _detach$ = new Subject<void>();
  public get detach$() {
    return this._detach$.asObservable();
  }

  constructor(protected overlay: Overlay) {}

  public createComponentInjector<T>(
    token: InjectionToken<T>,
    useValue: Partial<T>,
  ): Injector {
    return Injector.create({
      providers: [
        {
          provide: token,
          useValue,
        },
      ],
    });
  }

  public createComponentPortal<T>(
    component: ComponentType<T>,
    injector?: Injector,
    viewContainerRef?: ViewContainerRef,
    projectableNodes?: Node[][],
  ): ComponentPortal<T> {
    return new ComponentPortal(
      component,
      viewContainerRef,
      injector,
      undefined,
      projectableNodes,
    );
  }

  public createOverlayRef(config?: OverlayConfig): OverlayRef {
    return this.overlay.create(config);
  }

  public setOverlayRef(overlayRef: OverlayRef): void {
    if (this._overlayRef()) this._overlayRef()?.dispose();
    this._overlayRef.set(overlayRef);
  }

  public attachToOverlay<T>(componentPortal: ComponentPortal<T>): void {
    const overlayRef = this.overlayRef();
    if (!overlayRef || overlayRef.hasAttached()) return;

    const componentRef = overlayRef.attach(componentPortal);
    this._componentRef.set(componentRef);
  }

  public detachFromOverlay(): void {
    this._overlayRef()?.detach();
    this._detach$.next();

    const componentRef = this.componentRef();
    if (!componentRef) return;

    componentRef.destroy();
    this._componentRef.set(null);
  }

  public addPanelClass(className: string): void {
    return this._overlayRef()?.addPanelClass(className);
  }

  public removePanelClass(className: string): void {
    return this._overlayRef()?.removePanelClass(className);
  }
}
