import { Inject, Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ONYX_HERE_MAPS_API_KEY } from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class OnyxHereMapsService {
  private platform = signal<H.service.Platform | null>(null);

  constructor(
    @Inject(ONYX_HERE_MAPS_API_KEY) private apiKey: string,
    private translateService: TranslateService,
  ) {}

  public getDefaultLayers(): H.service.DefaultLayers {
    const language =
      this.translateService.currentLang ?? this.translateService.defaultLang;
    const platform = this.getPlatform();

    return platform.createDefaultLayers({ lg: language }, undefined, language);
  }

  private getPlatform(): H.service.Platform {
    this.platform.update((platform) => {
      if (platform != null) return platform;
      return new H.service.Platform({ apikey: this.apiKey });
    });
    return this.platform()!;
  }
}
