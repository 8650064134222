import { Injectable } from '@angular/core';
import { orderBy } from 'lodash';
import { map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {
  OnyxUserSetting,
  OnyxUserSettingDto,
  OnyxUserSettings,
} from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class OnyxUserSettingsService {
  private readonly STORAGE_KEY = 'onyxUserSettings';

  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  public getSettings(scope: string): Observable<OnyxUserSettings> {
    return of(localStorage.getItem(`${this.STORAGE_KEY}-${scope}`)).pipe(
      map((settings) => (settings ? JSON.parse(settings) : [])),
      map((settings) => ({
        settings: orderBy(settings, 'name'),
        selected: JSON.parse(
          localStorage.getItem(`${this.STORAGE_KEY}-${scope}-selected`) ??
            'null',
        ),
      })),
    );
  }

  public addSetting(
    scope: string,
    setting: OnyxUserSettingDto,
  ): Observable<OnyxUserSetting> {
    const newSetting = {
      ...setting,
      uuid: uuidv4(),
    };

    return this.getSettings(scope).pipe(
      map(({ settings }) => [...settings, newSetting]),
      switchMap((settings) => this.setSettings(scope, settings)),
      map(() => newSetting),
      tap(() => this._reload$.next()),
    );
  }

  public editSetting(
    scope: string,
    uuid: string,
    setting: OnyxUserSettingDto,
  ): Observable<OnyxUserSetting> {
    const updatedSetting = {
      ...setting,
      uuid,
    };

    return this.getSettings(scope).pipe(
      map(({ settings }) =>
        settings.map((s) => (s.uuid === uuid ? updatedSetting : s)),
      ),
      switchMap((settings) => this.setSettings(scope, settings)),
      map(() => updatedSetting),
      tap(() => this._reload$.next()),
    );
  }

  public deleteSetting(scope: string, uuid: string): Observable<void> {
    return this.getSettings(scope).pipe(
      map(({ settings }) =>
        settings.filter((setting) => setting.uuid !== uuid),
      ),
      switchMap((settings) => this.setSettings(scope, settings)),
      tap(() => this._reload$.next()),
    );
  }

  public selectSetting(scope: string, uuid: string | null): Observable<void> {
    return of(
      localStorage.setItem(
        `${this.STORAGE_KEY}-${scope}-selected`,
        JSON.stringify(uuid),
      ),
    );
  }

  private setSettings(
    scope: string,
    settings: OnyxUserSetting[],
  ): Observable<void> {
    return of(
      localStorage.setItem(
        `${this.STORAGE_KEY}-${scope}`,
        JSON.stringify(settings),
      ),
    );
  }
}
