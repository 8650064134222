import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { OnyxModalService } from '@onyx/angular';
import { DriversRoute } from '../../drivers.routes';
import { AddDriverModalComponent } from '../components/add-driver-modal/add-driver-modal.component';
import { DriversRouterStateKey } from '../enums/drivers-router-state-key';

export const addDriverGuard: CanActivateFn = () => {
  const router = inject(Router);
  const modalService = inject(OnyxModalService);

  const state = router.getCurrentNavigation()?.extras?.state;
  const privatePhone = state?.[DriversRouterStateKey.PRIVATE_PHONE];
  const nationality = state?.[DriversRouterStateKey.NATIONALITY];

  if (privatePhone && nationality) return true;

  modalService.open(AddDriverModalComponent);
  return router.parseUrl(DriversRoute.DRIVERS_LIST);
};
