import { DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  signal,
  TemplateRef,
  viewChild,
} from '@angular/core';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxModalComponent,
  OnyxModalService,
  OnyxPhone,
  OnyxPhoneNumberInputComponent,
  onyxPhoneValidator,
  OnyxTextFieldComponent,
  OnyxToastService,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { DictionariesService } from '../../../../../common/services/dictionaries.service';
import { PreferencesService } from '../../../../../common/services/preferences.service';
import { DriverModalComponent } from '../../../driver-modal/driver-modal.component';
import { DriversRoute } from '../../../drivers.routes';
import { DriversRouterStateKey } from '../../enums/drivers-router-state-key';
import { Driver } from '../../interfaces/driver';
import { DriversService } from '../../services/drivers.service';

@Component({
  selector: 'app-add-driver-modal',
  standalone: true,
  imports: [
    OnyxModalComponent,
    TranslateModule,
    ReactiveFormsModule,
    OnyxDropdownComponent,
    OnyxTextFieldComponent,
    OnyxPhoneNumberInputComponent,
    OnyxButtonComponent,
    AsyncPipe,
  ],
  templateUrl: './add-driver-modal.component.html',
  styleUrl: './add-driver-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDriverModalComponent {
  protected readonly I18N = 'drivers.addDriverModal';

  protected readonly countries$ = this.dictionariesService.getDictionary(
    DictionaryCode.COUNTRY,
  );

  private readonly conflictToastTemplate = viewChild.required<
    TemplateRef<unknown>
  >('conflictToastTemplate');

  protected form = this.buildForm();
  protected loading = signal(false);
  protected close$ = new Subject<void>();

  private driver = signal<Driver | null>(null);

  constructor(
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private dictionariesService: DictionariesService,
    private toastService: OnyxToastService,
    private router: Router,
    private preferencesService: PreferencesService,
    private driversService: DriversService,
    private modalService: OnyxModalService,
  ) {}

  protected next(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const { privatePhone, nationality } = this.form.value;

    this.loading.set(true);
    this.driversService
      .listDrivers({
        phoneAreaCode: privatePhone?.areaCode,
        phoneNumber: privatePhone?.number,
        page: 1,
        limit: 999,
      })
      .subscribe({
        next: (value) => {
          if (value.items.length === 0) {
            this.router.navigateByUrl(DriversRoute.ADD_DRIVER, {
              state: {
                [DriversRouterStateKey.PRIVATE_PHONE]: privatePhone,
                [DriversRouterStateKey.NATIONALITY]: nationality,
              },
            });
            this.close$.next();
          } else {
            this.driver.set(value.items[0]);
            this.toastService.showError(this.conflictToastTemplate());
          }
        },
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  protected showExistingDriver(): void {
    this.modalService.open<Driver>(DriverModalComponent, this.driver()!);
  }

  private buildForm() {
    return this.fb.group({
      privatePhone: this.fb.control<OnyxPhone | null>(null, [
        Validators.required,
        onyxPhoneValidator,
      ]),
      nationality: this.fb.control<string | null>(
        this.preferencesService.defaultCountry(),
        [Validators.required],
      ),
    });
  }
}
