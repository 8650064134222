<div
  class="checkbox"
  [ngClass]="{
    active: value(),
    disabled: disabled(),
    invalid: isInvalid(),
  }"
  [tabindex]="disabled() ? -1 : 0"
  (focusout)="onTouched?.()"
>
  @if (value()) {
    <onyx-icon
      class="checkbox-icon"
      [name]="indeterminate() ? 'indeterminate' : 'check'"
      [size]="indeterminate() ? 16 : 10"
    ></onyx-icon>
  }
</div>

@if (label(); as label) {
  <onyx-input-label
    type="title"
    [label]="label"
    [optional]="!!formControl() && isOptional() && showOptional()"
    [disabled]="disabled()"
  ></onyx-input-label>
}

@if (formControl() && showErrors()) {
  <onyx-form-control-error
    [formControls]="[formControl()!]"
    type="checkbox"
  ></onyx-form-control-error>
}
