import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateTime } from 'luxon';

export const onyxDateValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const value = control.value;
  if (!value) return null;

  const { indefinite, date, from, to } = value;

  if (DateTime.fromISO(value).isValid) return null;

  if (indefinite && (date === null || date === undefined)) return null;

  if (!indefinite && DateTime.fromISO(date).isValid) return null;

  if (from && to) {
    const fromDate = DateTime.fromISO(from);
    const toDate = DateTime.fromISO(to);

    if (fromDate.isValid && toDate.isValid) {
      return fromDate > toDate ? { dateRange: true } : null;
    }
  }

  if (from && indefinite) {
    return DateTime.fromISO(from).isValid ? null : { dateRange: true };
  }

  return { dateRange: true };
};
