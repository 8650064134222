export enum OnyxToastColor {
  RED = 'red',
  GREEN = 'green',
  GRAY = 'gray',
}

export enum OnyxToastType {
  INVALID_DATA = 'invalidData',
  SERVER_ERROR = 'serverError',
  SAVED_CHANGES = 'savedChanges',
  COPIED_TO_CLIPBOARD = 'copiedToClipboard',
  FORM_CHANGES_RESTORED = 'formChangesRestored',
}
