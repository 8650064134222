export enum OnyxMapVehicleStatus {
  FREE_NO_DELEGATION_UNDER_24H = 'free-no-delegation-under-24h',
  FREE_NO_DELEGATION_OVER_24H = 'free-no-delegation-over-24h',
  FREE_UNDER_24H = 'free-under-24h',
  FREE_OVER_24H = 'free-over-24h',
  EARLY = 'early',
  DELAY_RISK = 'delay-risk',
  DELAYED = 'delayed',
  TRAILER = 'trailer',
}
