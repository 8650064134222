<div class="container" [ngClass]="{ disabled: disabled() }">
  <div
    class="radio {{ size() }}"
    [ngClass]="{
      active: active(),
      invalid: isInvalid(),
    }"
    [tabindex]="disabled() ? -1 : 0"
    (focusout)="onTouched?.()"
  ></div>

  @if (label(); as label) {
    <onyx-input-label
      type="title"
      [label]="label"
      [disabled]="disabled()"
    ></onyx-input-label>
  }
</div>
