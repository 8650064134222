<div
  class="container {{ type() }}"
  [ngClass]="{ 'close-animation': closeAnimation() }"
>
  <div class="dialog {{ color() }} {{ size() }}">
    <div
      class="top-bar"
      [ngClass]="{
        'extended-m':
          (avatarUser() || avatarCompany() || roles()) && !isCenter(),
        'extended-s': extend() && !isCenter(),
      }"
    >
      <div class="title-container">
        @if (avatarUser() || avatarCompany()) {
          <onyx-avatar
            [type]="avatarUser() ? 'user' : 'company'"
            [user]="$any(avatarUser())"
            [company]="$any(avatarCompany())"
            size="l"
          ></onyx-avatar>
        }

        <div class="title-wrapper">
          <div class="title">
            @if (isCenter()) {
              <div class="mobile">
                <p class="f-paragraph-medium-2">
                  {{ heading() }}
                </p>
              </div>

              <div class="desktop">
                <ng-content select="[headingIcon]"></ng-content>

                <h2>{{ heading() }}</h2>
              </div>
            } @else {
              <div class="title-group">
                <div class="text-group">
                  <div class="number-group">
                    <div class="heading-container">
                      <ng-content select="[headingLeftOptions]"></ng-content>
                      <h3>{{ heading() }}</h3>
                    </div>
                    @if (count() != null) {
                      <h4 class="count">{{ count() }}</h4>
                    }
                  </div>

                  <div class="left-options">
                    <ng-content select="[leftOptions]"></ng-content>
                  </div>
                </div>

                @if (roles()) {
                  <div class="roles">
                    @for (role of roles(); track role) {
                      <onyx-badge [color]="role.color">
                        {{
                          (dictionaryCode()
                            ? dictionaryCode() + '.' + role.value
                            : role.value
                          ) | translate
                        }}
                      </onyx-badge>
                    }
                  </div>
                } @else if (extend()) {
                  <ng-content select="[bottomOptions]"></ng-content>
                }
              </div>
            }
          </div>
        </div>
      </div>

      <div class="right-side">
        <ng-content select="[rightOptions]"></ng-content>

        @if (showClose()) {
          <div class="internal-close-button">
            <onyx-icon-button
              type="transparent"
              color="black"
              [size]="isCenter() ? 'l' : 'm'"
              (click)="closeDialog()"
            >
              <onyx-icon name="close" [size]="16"></onyx-icon>
            </onyx-icon-button>
          </div>
        }
      </div>
    </div>

    <div
      class="content"
      [ngClass]="{ padding: padding(), center: type() === 'center' }"
    >
      <ng-content></ng-content>
    </div>

    @if (isCenter()) {
      <div class="bottom-bar">
        <div class="bar-group">
          <ng-content select="[bottomLeftOptions]"></ng-content>
        </div>
        <div class="bar-group">
          <ng-content select="[bottomRightOptions]"></ng-content>
        </div>
      </div>
    }
  </div>
</div>
