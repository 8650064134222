import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  forwardRef,
  HostListener,
  Injector,
  input,
  model,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { OnyxBaseFormControlComponent } from '../../../internal/components/onyx-base-form-control/onyx-base-form-control.component';
import { OnyxFormControlErrorComponent } from '../../../internal/components/onyx-form-control-error/onyx-form-control-error.component';
import { OnyxCustomIconComponent } from '../../icons';
import { OnyxIconComponent } from '../../icons/onyx-icon/onyx-icon.component';
import { OnyxInputLabelComponent } from '../../labels/onyx-input-label/onyx-input-label.component';

@Component({
  selector: 'onyx-checkbox',
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    OnyxCustomIconComponent,
    OnyxInputLabelComponent,
    OnyxIconComponent,
    OnyxFormControlErrorComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnyxCheckboxComponent),
      multi: true,
    },
  ],
  templateUrl: './onyx-checkbox.component.html',
  styleUrls: ['./onyx-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxCheckboxComponent
  extends OnyxBaseFormControlComponent
  implements ControlValueAccessor
{
  public label = input<string>();
  public value = model(false);
  public disabled = model(false);
  public indeterminate = input(false);
  public showOptional = input(true);
  public showErrors = input(true);

  protected onChange?: (value: boolean) => void;
  protected onTouched?: () => void;

  constructor(
    protected override injector: Injector,
    protected override destroyRef: DestroyRef,
  ) {
    super(injector, destroyRef);
  }

  public registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled.set(disabled);
  }

  public writeValue(value: boolean): void {
    this.value.set(value);
  }

  @HostListener('click', ['$event'])
  @HostListener('keydown.space', ['$event'])
  protected switchValue(event: Event): void {
    if (this.disabled()) return;

    this.changeValue(!this.value());
    event.preventDefault();
  }

  protected changeValue(value: boolean): void {
    this.value.set(value);
    this.onChange?.(value);
    this.onTouched?.();
  }
}
